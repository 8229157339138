import React, { useState } from "react"
import scrollToElement from "scroll-to-element"
import clsx from "clsx"

const BuildingOutside = ({ setFloor }) => {
  const [current, setCurrent] = useState(undefined)

  const handleClick = floor => {
    setFloor(floor)
    scrollToElement("#floors", {
      offset: -125,
      duration: 200,
    })

    setTimeout(() => {
      setCurrent(undefined)
    }, 200)
  }

  const handleMouseLeave = () => setCurrent(undefined);

  return (
    <>
      <img
        src={
          require("assets/images/building/lobzow-apartment-view.jpg").default
        }
        alt=""
      />
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="1318"
        height="740"
        viewBox="0 0 1318 740"
      >
        <g id="NP">
          <polygon
            className={clsx("cls-2", current === -1 && "current")}
            points="83.37 236.01 115.19 239.43 110.17 243.31 115.76 254.71 133.32 258.25 142.68 249.01 152.37 249.35 168.11 251.18 165.6 253.57 170.73 265.21 188.64 268.28 195.25 260.64 198.67 269.2 225.13 271.59 217.6 279.94 163.07 271.59 134.65 262.71 112.88 255.95 89.21 252.36 83.37 236.01"
            onClick={() => handleClick(-1)}
            onMouseEnter={() => setCurrent(-1)}
            onMouseLeave={handleMouseLeave}
          />
          <path
            className={clsx("cls-2", current === -1 && "current")}
            d="m491.17,531.48l-7.15-1.76c-.33-.08-.69.04-.89.32l-8.08,10.79c-.2.26-.53.39-.85.33l-62.16-11.81c-.38-.07-.76.12-.93.46l-.83,1.67c-.18.36-.58.54-.96.45l-169.69-39.8,9.54,20.36,58.23,13.68,8.59-12.09,98.01,24.18,3.5,13.05,63,14.96,7.9-14.99-2.29-8.93,4.57-8.93.48-1.93Z"
            onClick={() => handleClick(-1)}
            onMouseEnter={() => setCurrent(-1)}
            onMouseLeave={handleMouseLeave}
          />
          <path
            className={clsx("cls-1", current === -1 && "current")}
            d="m500.5,488.65c-.31.49-.07,1.14.49,1.29"
            onClick={() => handleClick(-1)}
            onMouseEnter={() => setCurrent(-1)}
            onMouseLeave={handleMouseLeave}
          />
          <path
            className={clsx("cls-2", current === -1 && "current")}
            d="m850.77,569.92l6.4.3c.41.02.75.33.81.73l7.54,51.73c.02.12,0,.25-.03.37l-1.68,5.61c-.03.11-.04.23-.03.34l1.68,15.32.07,4.38h-7.74l-7.01-76.12v-2.67Z"
            onClick={() => handleClick(-1)}
            onMouseEnter={() => setCurrent(-1)}
            onMouseLeave={handleMouseLeave}
          />
          <path
            className={clsx("cls-2", current === -1 && "current")}
            d="m813.35,410.49c-.05-.15-.06-.31-.02-.46l1.94-8.57c.09-.39.44-.67.84-.67h.14c.44,0,.81-.33.86-.77l.98-9.59c.01-.11,0-.21-.03-.32l-1.32-4.79c-.04-.15-.04-.3,0-.45l.76-2.89c.05-.18.04-.37-.03-.54l-18.27-45.4-6.88-18.53c-.05-.13-.06-.27-.05-.41l.3-2.42c.02-.15,0-.3-.06-.44l-8.92-21.17c-.06-.13-.08-.28-.06-.42l1.67-16.18c.05-.49-.32-.92-.82-.95l-62.83-2.78c-.47-.02-.86.33-.9.8l-.77,10.82c-.03.46-.43.82-.89.8l-84.31-3.5c-.47-.02-.83-.41-.82-.87l.15-11.14c0-.48-.37-.87-.85-.87l-51.38-.52c-.42,0-.78-.31-.84-.73l-.14-.89c-.06-.41-.41-.72-.83-.73l-5.11-.13c-.51-.01-.92.41-.88.92l1.35,18.85c0,.12,0,.24-.05.35l-5.26,14.54c-.02.06-.04.12-.04.18l-.88,6.86-1.96,17.07s0,.07-.02.1l-4.77,19.97c-.02.09-.03.19-.02.28l1.04,10.58c.01.1,0,.21-.03.31l-5.27,19.13c-.03.11-.04.21-.03.32l1.02,9.19c.02.18-.02.36-.11.52l-5.55,9.83c-.28.5,0,1.12.55,1.26l4.2,1.02c.35.08.61.38.65.73l1.33,11.11c.02.16,0,.33-.08.48l-7.15,14.81h7.38l11.62-78.18-2.74-.13-1.44-16.45,6.92.13,4.31-26.9-2.87-.13-.65-14.23,1.18-2.87-.52-3.13h6.01l.78,7.83,192.61,7.7,5.75,19.85,9.4,24.16,8.1-.65-2.48,16.06,12.27,34.73,4.82,18.5Z"
            onClick={() => handleClick(-1)}
            onMouseEnter={() => setCurrent(-1)}
            onMouseLeave={handleMouseLeave}
          />
          <polygon
            className={clsx("cls-2", current === -1 && "current")}
            points="545.12 434.54 533.05 432.18 532.52 428.8 523.42 446.1 515.39 464.12 505.05 481.6 499.87 489.63 505.58 491.23 508.44 502.83 498.4 522.65 501.83 522.65 503.66 519.8 506.7 520.21 539.14 454.5 535.9 453.08 545.12 434.54"
            onClick={() => handleClick(-1)}
            onMouseEnter={() => setCurrent(-1)}
            onMouseLeave={handleMouseLeave}
          />
        </g>
        <g id="WP">
          <polyline
            className={clsx("cls-2", current === 0 && "current")}
            points="256.38 240.12 252.96 239.43 227.75 264.52 228.66 266.35 225.13 271.59 198.67 269.2 195.25 260.64 188.64 268.28 170.73 265.21 165.6 253.57 168.11 251.18 152.37 249.35 142.68 249.01 133.32 258.25 115.76 254.71 110.17 243.31 115.19 239.43 83.37 236.01 72.05 213.61 102.21 216.48 98.66 220.09 104.12 231.76 121.85 234.76 131.81 225.76 140.96 224.66 158.15 227.39 155.15 229.44 160.47 241.72 178.61 244.59 185.36 237.76 189.12 246.36 216.27 248.13 224.6 237.63 230.74 236.4 257.75 238.72"
            onClick={() => handleClick(0)}
            onMouseEnter={() => setCurrent(0)}
            onMouseLeave={handleMouseLeave}
          />
          <polygon
            className={clsx("cls-2", current === 0 && "current")}
            points="225.87 463.15 401.62 504.85 403.26 501.76 469 513.96 478.11 504.85 488.49 506.68 503.61 477.72 501.24 465.88 497.41 465.15 529.47 404.14 529.47 406.33 544.4 409.42 557.51 383.74 556.61 371.41 549.48 370 555.83 357.55 555.5 353.46 560.72 326.51 560.07 318.25 565.07 296.08 568.98 290.43 568.33 276.96 572.67 256.31 572.06 243.56 579.5 243.09 579.68 244.63 633.98 246.3 633.61 258.48 721.32 260.7 721.51 251.28 787.62 253.68 786.32 266.24 797.22 290.25 797.04 296.52 821.41 354.88 820.3 358.2 823.44 366.33 817.9 386.64 831.94 424.68 833.23 432.44 836.74 473.8 844.68 473.99 842.65 479.16 844.49 496.33 841.35 496.15 838.4 499.66 841.72 543.42 864.07 545.64 872.75 624.86 1020.11 634.46 1021.77 642.22 1160.8 652.86 1164.85 664.79 1157.18 675.85 1009.85 665.33 1007.71 658.19 865.9 648.38 863.76 628.83 865.54 622.87 857.87 570.25 835.22 569.18 833.61 519.06 837.18 518.17 836.29 500.15 830.4 499.44 827.73 459.47 826.12 448.6 820.06 430.94 813.28 410.27 815.42 400.8 817.02 400.8 818.09 390.27 816.67 385.1 817.56 381.71 799.19 336.05 792.23 317.32 792.59 314.46 783.49 292.88 785.27 275.58 720.7 272.72 719.81 285.21 633.83 281.64 634.01 268.8 581.03 268.27 580.68 265.95 573.9 265.77 575.33 285.75 569.98 300.55 569.08 307.51 567.12 324.63 562.31 344.79 563.38 355.67 558.02 375.11 559.09 384.74 552.85 395.8 558.74 397.23 560.16 409.18 547.68 435.05 533.05 432.18 532.52 428.8 523.42 446.1 515.39 464.12 505.05 481.6 499.87 489.63 505.58 491.23 508.44 502.83 493.63 532.08 483.46 529.58 474.72 541.25 411.4 529.23 409.97 532.08 239.63 492.13 225.87 463.15"
            onClick={() => handleClick(0)}
            onMouseEnter={() => setCurrent(0)}
            onMouseLeave={handleMouseLeave}
          />
        </g>
        <g id="_1" data-name="1">
          <polygon
            className={clsx("cls-2", current === 1 && "current")}
            points="61.44 192.05 89.4 192.47 86.47 194.71 92.34 207.29 110.79 210.23 120.86 201.14 131.06 201.56 146.44 202.82 144.06 204.77 149.51 217.5 168.11 220.29 175.66 212.74 179.4 221.83 206.55 223.93 215.5 214 214.8 212.18 249.05 212.6 251.56 219.59 263.59 219.59 253.8 229.17 254.21 230.8 258.57 230.8 260.21 236.13 257.75 238.72 230.74 236.4 224.6 237.63 216.27 248.13 189.12 246.36 185.36 237.76 178.61 244.59 160.47 241.72 155.15 229.44 158.15 227.39 140.96 224.66 131.81 225.76 121.85 234.76 104.12 231.76 98.66 220.09 102.21 216.48 72.05 213.61 61.44 192.05"
            onClick={() => handleClick(1)}
            onMouseEnter={() => setCurrent(1)}
            onMouseLeave={handleMouseLeave}
          />
          <polygon
            className={clsx("cls-2", current === 1 && "current")}
            points="214.05 438.95 394.13 479.36 396.1 476.73 463.52 487.49 472.53 478.26 483.73 481.12 499.76 450.15 496.68 430.39 507.88 410.27 516.45 396.79 526.55 380.32 541.7 382.66 554.66 357.7 553.34 343.86 547.85 342.98 555.54 327.83 554.66 322.56 558.17 300.16 557.08 289.62 566.96 263.71 566.08 247.9 571.13 230.11 570.17 219.28 577.91 218.78 577.99 220.52 633.51 221.84 633.51 233.27 723.3 236.19 723.57 225.03 791.05 227.95 789.19 241.77 800.61 264.88 800.35 269.93 807.25 284.8 821.33 313.23 821.07 318.28 827.44 337.14 822.4 358.92 837.01 396.38 839.13 407.8 840.99 446.33 863.31 447.92 866.76 470.23 847.63 468.91 844.18 479.8 847.37 517.52 871.01 519.38 878.98 575.44 878.18 578.09 1029.08 587.12 1034.92 616.61 1193.26 626.18 1194.05 628.83 1202.02 628.83 1199.18 633.66 1190.89 633.04 1190.11 631.32 1185.75 631.69 1190.93 644.06 1183.35 654.59 1021.77 642.22 1020.11 634.46 872.75 624.86 864.07 545.64 841.72 543.42 838.4 499.66 841.35 496.15 844.49 496.33 842.65 479.16 844.68 473.99 836.74 473.8 833.23 432.44 831.94 424.68 817.9 386.64 823.44 366.33 820.3 358.2 821.41 354.88 797.04 296.52 797.22 290.25 786.32 266.24 787.62 253.68 721.51 251.28 721.32 260.7 633.61 258.48 633.98 246.3 579.68 244.63 579.5 243.09 572.06 243.56 572.67 256.31 568.33 276.96 568.98 290.43 565.07 296.08 560.07 318.25 560.72 326.51 555.5 353.46 555.83 357.55 549.48 370 556.61 371.41 557.51 383.74 544.4 409.42 529.47 406.33 529.47 404.14 497.41 465.15 501.24 465.88 503.61 477.72 488.49 506.68 478.11 504.85 469 513.96 403.26 501.76 401.62 504.85 225.87 463.15 214.05 438.95"
            onClick={() => handleClick(1)}
            onMouseEnter={() => setCurrent(1)}
            onMouseLeave={handleMouseLeave}
          />
        </g>
        <g id="_2" data-name="2">
          <polygon
            className={clsx("cls-2", current === 2 && "current")}
            points="47.26 164.45 75.46 167.26 73.77 168.8 79.81 181.71 98.32 184.79 109.13 175.68 118.52 175.12 134.66 177.5 132.69 178.48 137.88 191.25 157.38 194.9 164.68 187.6 168.04 196.02 196.52 198.12 205.36 188.16 204.24 185.64 239.59 187.04 242.53 193.77 262.59 193.77 269.19 183.67 285.6 168.94 291.49 163.89 303.7 163.89 293.78 181.01 293.64 182.55 311.54 182.97 310.42 185.62 306.09 189.54 299.24 189.68 293.08 193.59 288.19 196.11 263.59 219.59 251.56 219.59 249.05 212.6 214.8 212.18 215.5 214 206.55 223.93 179.4 221.83 175.66 212.74 168.11 220.29 149.51 217.5 144.06 204.77 146.44 202.82 131.06 201.56 120.86 201.14 110.79 210.23 92.34 207.29 86.47 194.71 89.4 192.47 61.44 192.05 47.26 164.45"
            onClick={() => handleClick(2)}
            onMouseEnter={() => setCurrent(2)}
            onMouseLeave={handleMouseLeave}
          />
          <polygon
            className={clsx("cls-2", current === 2 && "current")}
            points="200.73 411.17 386.32 453 387.9 450.62 458.33 465.71 468.39 451.68 478.45 453.27 495.12 423.09 491.68 402.7 522.92 348.96 523.19 351.61 539.07 354.25 552.31 329.1 550.98 315.07 545.42 314.01 552.57 297.86 552.57 292.3 557.34 282.51 556.01 265.83 565.02 240.15 563.96 223.93 568.99 205.73 568.19 191.97 576.4 191.7 576.66 193.91 633.32 195.67 633.05 207.59 724.92 210.5 725.18 199.65 794.28 202.03 793.22 211.82 804.87 238.56 804.08 240.94 835.05 307.13 834.52 310.57 837.96 318.25 830.28 319.04 827.64 333.34 842.73 370 845.11 388.93 866.02 390.52 879.79 501.18 1035.99 541.16 1048.96 589.87 1213.36 599.93 1222.89 586.43 1219.72 578.49 1223.68 578.82 1229.74 593.22 1220.61 605.23 1199.33 603.86 1205.48 619 1200.69 624.85 1202.02 628.83 1194.05 628.83 1193.26 626.18 1034.92 616.61 1029.08 587.12 878.18 578.09 878.98 575.44 871.01 519.38 847.37 517.52 844.18 479.8 847.63 468.91 866.76 470.23 863.31 447.92 840.99 446.33 839.13 407.8 837.01 396.38 822.4 358.92 827.44 337.14 821.07 318.28 821.33 313.23 807.25 284.8 800.35 269.93 800.61 264.88 789.19 241.77 791.05 227.95 723.57 225.03 723.3 236.19 633.51 233.27 633.51 221.84 577.99 220.52 577.91 218.78 570.17 219.28 571.13 230.11 566.08 247.9 566.96 263.71 557.08 289.62 558.17 300.16 554.66 322.56 555.54 327.83 547.85 342.98 553.34 343.86 554.66 357.7 541.7 382.66 526.55 380.32 516.45 396.79 507.88 410.27 496.68 430.39 499.76 450.15 483.73 481.12 472.53 478.26 463.52 487.49 396.1 476.73 394.13 479.36 214.05 438.95 200.73 411.17"
            onClick={() => handleClick(2)}
            onMouseEnter={() => setCurrent(2)}
            onMouseLeave={handleMouseLeave}
          />
        </g>
        <g id="_3" data-name="3">
          <polygon
            className={clsx("cls-2", current === 3 && "current")}
            points="34.46 139.52 60.41 141.62 67.01 155.37 85.95 158.32 96.89 149.62 96.19 148.08 120.31 151.02 125.64 164.77 145.71 168.14 153.42 160.84 157.07 169.68 185.36 171.22 195.51 161.12 194.66 159.58 230.3 160.84 232.82 167.01 253.58 167.01 260.46 156.91 277.15 144.15 283.6 138.4 312.22 138.4 316.99 137.27 326.81 123.95 303.7 163.89 291.49 163.89 285.6 168.94 269.19 183.67 262.59 193.77 242.53 193.77 239.59 187.04 204.24 185.64 205.36 188.16 196.52 198.12 168.04 196.02 164.68 187.6 157.38 194.9 137.88 191.25 132.69 178.48 134.66 177.5 118.52 175.12 109.13 175.68 98.32 184.79 79.81 181.71 73.77 168.8 75.46 167.26 47.26 164.45 34.46 139.52"
            onClick={() => handleClick(3)}
            onMouseEnter={() => setCurrent(3)}
            onMouseLeave={handleMouseLeave}
          />
          <polygon
            className={clsx("cls-2", current === 3 && "current")}
            points="186.6 381.64 376.89 422.72 379.59 419.75 451.49 432.18 461.22 422.18 472.85 424.88 489.06 394.61 485.82 380.01 501.23 352.17 500.15 345.14 508.26 338.12 519.07 322.17 535.02 324.87 549.34 300 547.72 284.6 542.04 283.25 555.29 261.89 552.86 235.94 562.86 208.1 561.78 190.8 567.18 178.91 566.1 165.39 573.94 165.66 574.21 168.37 632.59 168.91 632.59 180.26 726.39 183.5 726.93 172.69 797.48 175.39 796.13 187.29 808.29 210.53 815.05 225.67 815.05 229.45 839.65 278.92 851.54 301.08 878.03 358.66 880.46 368.66 896.68 450.56 895.87 453.81 1042.37 488.4 1058.05 538.95 1158.87 545.17 1227.26 549.76 1229.42 546.25 1239.12 546.94 1246.72 564.72 1240.63 572.13 1242.22 576.11 1238.51 581.4 1230.04 581.4 1228.98 578.75 1226.07 579.02 1219.72 578.49 1222.89 586.43 1213.36 599.93 1048.96 589.87 1035.99 541.16 879.79 501.18 866.02 390.52 845.11 388.93 842.73 370 827.64 333.34 830.28 319.04 837.96 318.25 834.52 310.57 835.05 307.13 804.08 240.94 804.87 238.56 793.22 211.82 794.28 202.03 725.18 199.65 724.92 210.5 633.05 207.59 633.32 195.67 576.66 193.91 576.4 191.7 568.19 191.97 568.99 205.73 563.96 223.93 565.02 240.15 556.01 265.83 557.34 282.51 552.57 292.3 552.57 297.86 545.42 314.01 550.98 315.07 552.31 329.1 539.07 354.25 523.19 351.61 522.92 348.96 491.68 402.7 495.12 423.09 478.45 453.27 468.39 451.68 458.33 465.71 387.9 450.62 386.32 453 200.73 411.17 186.6 381.64"
            onClick={() => handleClick(3)}
            onMouseEnter={() => setCurrent(3)}
            onMouseLeave={handleMouseLeave}
          />
        </g>
        <g id="_4" data-name="4">
          <polygon
            className={clsx("cls-2", current === 4 && "current")}
            points="20.6 111.58 153.87 130.75 155.51 129.47 223.97 133.85 229.81 126.19 250.26 126.37 269.61 119.98 275.45 111.58 309.4 111.58 318.9 96.43 350.11 96.43 345.75 103.89 339.01 103.47 326.81 123.95 316.99 137.27 312.22 138.4 283.6 138.4 277.15 144.15 260.46 156.91 253.58 167.01 232.82 167.01 230.3 160.84 194.66 159.58 195.51 161.12 185.36 171.22 157.07 169.68 153.42 160.84 145.71 168.14 125.64 164.77 120.31 151.02 96.19 148.08 96.89 149.62 85.95 158.32 67.01 155.37 60.41 141.62 34.46 139.52 20.6 111.58"
            onClick={() => handleClick(4)}
            onMouseEnter={() => setCurrent(4)}
            onMouseLeave={handleMouseLeave}
          />
          <polygon
            className={clsx("cls-2", current === 4 && "current")}
            points="173.62 354.06 240.66 366.23 239.3 361.9 367.7 388.93 370.13 386.5 370.13 390.83 445 403.26 479.6 345.68 478.25 337.04 484.74 324.87 496.36 316.49 513.93 282.7 526.37 262.43 528.8 260.27 541.77 261.35 539.88 250 547.18 223.93 551.77 223.93 549.34 201.07 555.56 176.75 559.88 176.75 558.8 161.03 565.02 144.58 565.02 136.2 572.32 136.2 572.32 140.8 792.88 147.55 792.88 143.23 802.07 143.77 800.18 155.39 813.16 176.21 831 175.39 831 182.96 821.27 183.77 820.18 195.94 845.86 246.48 845.86 248.92 863.97 247.84 871 258.65 899.65 269.73 911.82 269.73 915.6 277.84 873.43 281.35 871.27 294.87 883.71 317.84 888.84 337.58 971.28 372.99 971.28 385.96 1014.8 395.42 1021.83 407.04 1036.97 410.02 1043.45 408.4 1176.98 512.19 1289.16 514.89 1284.56 520.84 1266.72 520.84 1273.21 535.44 1263.75 548.68 1229.42 546.25 1227.26 549.76 1158.87 545.17 1058.05 538.95 1042.37 488.4 895.87 453.81 896.68 450.56 880.46 368.66 878.03 358.66 851.54 301.08 839.65 278.92 815.05 229.45 815.05 225.67 808.29 210.53 796.13 187.29 797.48 175.39 726.93 172.69 726.39 183.5 632.59 180.26 632.59 168.91 574.21 168.37 573.94 165.66 566.1 165.39 567.18 178.91 561.78 190.8 562.86 208.1 552.86 235.94 555.29 261.89 542.04 283.25 547.72 284.6 549.34 300 535.02 324.87 519.07 322.17 508.26 338.12 500.15 345.14 501.23 352.17 485.82 380.01 489.06 394.61 472.85 424.88 461.22 422.18 451.49 432.18 379.59 419.75 376.89 422.72 186.6 381.64 173.62 354.06"
            onClick={() => handleClick(4)}
            onMouseEnter={() => setCurrent(4)}
            onMouseLeave={handleMouseLeave}
          />
        </g>
      </svg>
    </>
  )
}

export default BuildingOutside
