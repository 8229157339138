import "./styles.scss"

import React, { useState } from "react"
import { AnimatePresence, motion } from "framer-motion"
import { Tooltip } from "react-tooltip"
import clsx from "clsx"

import {
  BuildingFloor1,
  BuildingFloor2,
  BuildingFloor3,
  BuildingFloor4,
  BuildingFloorGroundHigh,
  BuildingFloorGroundLow,
  BuildingOutside,
  Switch,
} from "../components"

const Building = ({ apartmentList, apartmentData }) => {
  const [floor, setFloor] = useState(-1)

  return (
    <section className="apartments-building">
      <div className="apartments-building__header">
        <div className="container-fluid">
          <div className="apartments-building__heading">
            <h3>Wybierz mieszkanie</h3>
            <Switch {...apartmentData} />
          </div>
        </div>
      </div>
      <div className="apartments-building__view">
        <div className="container-fluid">
          <div className="apartments-building__view-building">
            <BuildingOutside setFloor={setFloor} />
          </div>
          <div id="floors" className="apartments-building__view-floors">
            <div className="apartments-building__view-floors-nav">
              {new Array(6).fill("_").map((_, index) => {
                index = index - 1

                return (
                  <button
                    key={index}
                    className={clsx(
                      (index === -1 || index === 0) && "ground",
                      floor === index && "active"
                    )}
                    data-tooltip-id={
                      index === -1 || index === 0
                        ? `tooltip-${index + 1}`
                        : undefined
                    }
                    onClick={() => setFloor(index)}
                  >
                    {getFloorName(index)}
                    {(index === -1 || index === 0) && (
                      <Tooltip
                        className="custom-tooltip"
                        id={`tooltip-${index + 1}`}
                        place="top"
                        content={getFloorName(index, true)}
                      />
                    )}
                  </button>
                )
              })}
              <p>Piętro</p>
            </div>
            <div className="apartments-building__view-floors-storeys">
              <span>
                <img
                  src={require("assets/images/ico-compass.png").default}
                  alt=""
                />
              </span>
              <AnimatePresence exitBeforeEnter initial={false}>
                <motion.div
                  key={floor}
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  exit={{ opacity: 0 }}
                  transition={{ type: "tween", duration: 0.2 }}
                >
                  {getFloorView(floor, apartmentList)}
                </motion.div>
              </AnimatePresence>
              <ul>
                <li className="status-green">Wolne</li>
                <li className="status-yellow">Zarezerwowane</li>
                <li className="status-red">Sprzedane</li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

const getFloorView = (floor, apartmentList) => {
  switch (floor) {
    case -1:
      return <BuildingFloorGroundLow apartmentList={apartmentList} />
    case 0:
      return <BuildingFloorGroundHigh apartmentList={apartmentList} />
    case 1:
      return <BuildingFloor1 apartmentList={apartmentList} />
    case 2:
      return <BuildingFloor2 apartmentList={apartmentList} />
    case 3:
      return <BuildingFloor3 apartmentList={apartmentList} />
    case 4:
      return <BuildingFloor4 apartmentList={apartmentList} />
    default:
      return <h2>Brak wybranego piętra</h2>
  }
}

const getFloorName = (floor, isFullName) => {
  switch (floor) {
    case -1:
      return !isFullName ? "PN" : "Parter niski"
    case 0:
      return !isFullName ? "PW" : "Parter wysoki"
    default:
      return floor
  }
}

export default Building
