export const getStatus = (apartments, slug) => {
  let status = undefined

  if (apartments && slug) {
    apartments.forEach(apartment => {
      if (apartment.slug === slug) {
        status = apartment.acfApartment.status
      }
    })
  }

  return status
}
