import React from "react"
import clsx from "clsx"

import { getStatusClass } from "../../utils"

const BuildingFloorGroundLow = ({ apartmentList }) => {
  return (
    <>
      <img
        src={require("assets/images/building/lobzow-floor-gl.png").default}
        alt=""
      />
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="1318"
        height="740"
        viewBox="0 0 1318 740"
      >
        <g id="A_1_1">
          <polygon
            className={clsx("cls-1", getStatusClass(apartmentList, "a-1-1"))}
            points="217.96 57.19 215.9 69.28 214.63 69.78 209.75 99.56 176 93.44 179.16 74.89 184.08 75.66 185.51 65.62 179.54 64.35 179.5 64.59 101.34 40.95 110.2 11.08 143.29 16.9 142.42 21.99 154.23 24.13 155.26 19 185.43 24.31 180.77 50.1 216.75 56.34 217.96 57.19"
            onClick={() => window.open("/mieszkania/a-1-1/", "_blank")}
          />
        </g>
        <g id="A_1_2">
          <polygon
            className={clsx("cls-1", getStatusClass(apartmentList, "a-1-2"))}
            points="251.57 93.85 239.3 164.68 234.22 163.45 233.65 164.37 170.58 145.86 170.94 144.68 167.75 143.46 175.97 96.86 212.12 103.3 216.53 74.32 217.78 74.46 218.11 73.25 230.9 75.44 230.7 76.62 248.69 79.51 246.34 93.02 251.57 93.85"
            onClick={() => window.open("/mieszkania/a-1-2/", "_blank")}
          />
        </g>
        <g id="A_1_3">
          <polygon
            className={clsx("cls-1", getStatusClass(apartmentList, "a-1-3"))}
            points="326.75 87.68 320.55 176.73 314.26 175.66 314.07 177.16 244.41 166.38 244.83 163.85 242.18 163.08 253.73 95.61 265.71 97.84 266.77 91.22 254.86 89 257.11 75.83 285.56 80.47 285.17 84.07 291.87 85.4 292.65 81.62 294.34 81.9 294.62 80.95 307.42 83.12 307.4 84.12 326.75 87.68"
            onClick={() => window.open("/mieszkania/a-1-3/", "_blank")}
          />
        </g>
        <g id="A_1_4">
          <polygon
            className={clsx("cls-1", getStatusClass(apartmentList, "a-1-4"))}
            points="396.86 91.91 396.41 98.41 405.23 99.24 403.16 128.93 374.32 127.06 374.48 125.55 362.08 124.69 361.97 126.22 362.61 126.27 361.11 147.8 347.95 162.43 347.87 163.42 326.64 162.08 326.99 160.8 324.25 160.54 328.9 93.51 338.65 94.07 338.82 89.39 339.62 89.22 339.72 87.83 329.35 87.02 329.46 85.37 311.84 82.28 312.26 79.75 311.05 79.43 313.09 66.5 314.49 66.73 314.69 65.47 321.59 66.36 407.09 72.43 405.7 92.48 396.86 91.91"
            onClick={() => window.open("/mieszkania/a-1-4/", "_blank")}
          />
        </g>
        <g id="B_1_1">
          <path
            className={clsx("cls-1", getStatusClass(apartmentList, "b-1-1"))}
            d="m574.3,106.68l-104.22-6.98-1.3,18.79-1.3-.34-3.84,55.37,84.94,5.87,2.5-36.5,20.51,1.39.12-1.93,1.3-.22.86-12.75-.98-.18,1.41-22.52Zm-15.49,21.11l-6.7-.45.88-11.68,6.65.54-.83,11.59Z"
            onClick={() => window.open("/mieszkania/b-1-1/", "_blank")}
          />
        </g>
        <g id="B_1_2">
          <polygon
            className={clsx("cls-1", getStatusClass(apartmentList, "b-1-2"))}
            points="568.33 194.8 569.24 194.87 568.4 207.93 567.15 207.96 566.2 219.45 555.99 218.72 556.74 208.14 550.26 207.62 549.56 218.26 460.76 211.92 462.65 187.08 464.02 187.04 464.75 176.42 550.67 182.39 552.52 155.98 559.86 156.64 560.54 146.13 571.49 146.98 568.33 194.8"
            onClick={() => window.open("/mieszkania/b-1-2/", "_blank")}
          />
        </g>
        <g id="B_1_3">
          <polygon
            className={clsx("cls-1", getStatusClass(apartmentList, "b-1-3"))}
            points="566.61 233.74 565.71 246.68 564.47 246.71 561.16 294.15 550.27 293.51 551.01 283.25 543.71 282.51 545.46 256.44 458.33 250.33 460.04 225.39 461.35 225.39 462.11 214.87 549.36 220.61 548.5 231.4 556.12 231.76 556.81 221.1 566.31 221.72 565.38 233.53 566.61 233.74"
            onClick={() => window.open("/mieszkania/b-1-3/", "_blank")}
          />
        </g>
        <g id="B_1_4">
          <path
            className={clsx("cls-1", getStatusClass(apartmentList, "b-1-4"))}
            d="m560.91,298.35l.06-1.39-20.49-1.44.14-1.51,2.45-35.3-85.13-5.93-1.83,26.19,7.28.39.14-1.38,5.06.35-1.36,19.37-1.33.04-1.14,17.31,1.27.17-.7,12.72,89.59,6.12,1.04-15.08,3.46.23.54-7.8,1.25-.03,1.02-12.96-1.32-.07Zm-15.34,21.58l-6.66-.32.47-8.54,6.68.61-.49,8.25Z"
            onClick={() => window.open("/mieszkania/b-1-4/", "_blank")}
          />
        </g>
        <g id="B_1_5">
          <path
            className={clsx("cls-1", getStatusClass(apartmentList, "b-1-5"))}
            d="m556.85,359.65l-5.84-1.81,3.4-10.43,3.23.97.8-11.5-105.37-7-3.62,12.01,8.12,2.82-5.04,17.14.95.54-2.06,7.49-1.23-.33-6.96,22.81,1.12.42-2.24,7.28,99.96,30.81,3.42-10.87-3.08-.96,1.38-4.23,3.08.8,7.83-24.85,1.18-16.72,1.03-.24.84-12.75-.98-.18.08-1.22Zm-16.46,32.5l-6.58-2,4.09-13.11,6.36,2.12-3.87,12.99Z"
            onClick={() => window.open("/mieszkania/b-1-5/", "_blank")}
          />
        </g>
        <g id="B_1_6">
          <polygon
            className={clsx("cls-1", getStatusClass(apartmentList, "b-1-6"))}
            points="542.1 435.13 538.32 447.38 537.1 447.13 531.01 466.77 519.88 463.43 523.11 453.08 515.69 450.83 512.43 461.2 482.16 452.12 467.48 499.75 416.14 483.83 419.76 472.59 418.64 472.1 425.59 449.37 426.82 449.68 431.38 434.12 430.36 433.79 437.43 410.94 438.65 411.28 440.87 403.21 541.24 433.57 541.03 434.73 542.1 435.13"
            onClick={() => window.open("/mieszkania/b-1-6/", "_blank")}
          />
        </g>
        <g id="B_1_7">
          <polygon
            className={clsx("cls-1", getStatusClass(apartmentList, "b-1-7"))}
            points="534.31 494.03 514.66 557.23 504.62 554.14 504.22 555.34 470.31 544.86 470.69 543.68 455.06 539.15 453.56 543.98 454.71 544.43 451.83 553.8 399.7 538.17 415.25 486.38 469.02 502.53 482.24 459.62 501.37 465.37 502.93 460.91 530.18 469.46 529.99 470.07 531.19 470.43 527.48 482.78 526.03 482.55 523.69 490.7 534.31 494.03"
            onClick={() => window.open("/mieszkania/b-1-7/", "_blank")}
          />
        </g>
        <g id="B_1_8">
          <polygon
            className={clsx("cls-1", getStatusClass(apartmentList, "b-1-8"))}
            points="637.66 561.2 638.55 561.77 628.12 595.52 626.87 595.3 624.27 603.76 573.31 588.32 576.91 576.64 561.35 571.62 560.92 572.84 527.19 562.47 527.4 561.17 517.36 558.06 536.63 494.66 547.3 498.1 549.91 489.77 548.74 489.31 552.53 476.89 553.76 477.39 553.99 476.66 583.02 485.65 580.88 492.56 587.25 494.42 589.58 487.68 653.91 507.59 637.66 561.2"
            onClick={() => window.open("/mieszkania/b-1-8/", "_blank")}
          />
        </g>
        <g id="B_1_9">
          <polygon
            className={clsx("cls-1", getStatusClass(apartmentList, "b-1-9"))}
            points="676.7 433.6 678.17 433.98 671.52 455.05 666.39 472.21 658.46 497.13 657.24 496.81 654.74 504.89 554.78 474.1 557.85 463.33 562.74 464.66 566.27 454.29 561.25 452.59 563.73 444.87 562.61 444.32 566.34 432.22 567.63 432.38 571.52 419.76 583.19 420.57 582.52 430.98 589.17 431.5 589.6 421.01 678.96 427.18 676.7 433.6"
            onClick={() => window.open("/mieszkania/b-1-9/", "_blank")}
          />
        </g>
        <g id="B_1_10">
          <polygon
            className={clsx("cls-1", getStatusClass(apartmentList, "b-1-10"))}
            points="693.3 385.57 690.36 425.28 597.09 418.94 597.85 408.19 591.1 407.77 590.54 418.5 576.04 417.51 580.19 403.37 576.88 402.36 578.77 397.11 579.63 386.99 578.4 386.78 579.3 373.84 580.31 373.43 580.58 369.94 587.44 370.35 588.09 364.14 581.08 363.66 582.66 343.45 675 349.87 674.82 352.36 676.11 352.53 674.11 384.25 693.3 385.57"
            onClick={() => window.open("/mieszkania/b-1-10/", "_blank")}
          />
        </g>
        <g id="B_1_11">
          <polygon
            className={clsx("cls-1", getStatusClass(apartmentList, "b-1-11"))}
            points="698.63 308.84 695.87 348.5 602.28 342.2 603.15 331.5 596.51 331.04 595.8 341.76 582.85 340.89 584.71 310.24 583.7 310.02 584.64 297.12 585.66 296.75 585.95 293 592.81 293.52 593.36 287.41 586.43 286.86 587.99 266.72 680.33 273.13 680.15 275.63 681.66 275.74 679.47 307.25 698.63 308.84"
            onClick={() => window.open("/mieszkania/b-1-11/", "_blank")}
          />
        </g>
        <g id="B_1_12">
          <polygon
            className={clsx("cls-1", getStatusClass(apartmentList, "b-1-12"))}
            points="703.89 233.19 701.21 271.82 607.63 265.26 608.56 254.49 601.92 254.44 601.2 264.81 591.64 264.14 592.43 249.3 589.24 248.81 590.46 230.67 589.23 230.62 590.16 217.63 591.33 217.71 591.51 216.33 598.22 216.8 598.49 210.51 595.39 210.43 596.78 190.23 676.72 195.79 676.54 198.29 678.18 198.35 676.13 226.12 674.6 226.03 674.24 231.33 703.89 233.19"
            onClick={() => window.open("/mieszkania/b-1-12/", "_blank")}
          />
        </g>
        <g id="B_1_13">
          <polygon
            className={clsx("cls-1", getStatusClass(apartmentList, "b-1-13"))}
            points="748.17 108.3 747.65 114.97 761.18 116.11 753.73 201.39 751.25 201.18 750.68 202.57 720.54 200.01 720.73 198.5 719.19 198.37 720.25 183.28 715.23 182.58 714.65 183.8 695.88 182.41 696.07 180.91 681.03 179.43 688.4 97.99 711.31 99.38 711.85 96.14 713.25 96.17 713.3 94.96 726.23 95.86 726.06 97.13 762.62 99.67 761.77 109.42 748.17 108.3"
            onClick={() => window.open("/mieszkania/b-1-13/", "_blank")}
          />
        </g>
        <g id="B_1_14">
          <path
            className={clsx("cls-1", getStatusClass(apartmentList, "b-1-14"))}
            d="m836.41,135.71l2.59-29.62-19.56-1.71.19-1.28-13-1.12-.16,1.01-13.83-1.21-.67,5.49-11.96-1.03.44-5.37-15.28-.96-9.07,103.26,34.57,3.07.16-1.55,5.29.46-.19,1.51,34.01,2.99,5.06-57.86-4.81-.39,1.57-16.27,4.65.58Zm-31.12,13.45l-5.83-.4.74-6.82,4.72.5-.13,5.05.61.21-.11,1.46Z"
            onClick={() => window.open("/mieszkania/b-1-14/", "_blank")}
          />
        </g>
        <g id="C_1_1">
          <path
            className={clsx("cls-1", getStatusClass(apartmentList, "c-1-1"))}
            d="m904.38,112.06l.12-1.28-12.93-1.1-.08.99-7.68-.74-.51,5.43-12.06-1.18.39-5.43-7.62-.74-9.08,103.8,51.23,4.28,9.47-103.3-11.25-.73Zm-26.94,37.36l-6.07-.56.47-8.31,6.66.57-1.06,8.3Z"
            onClick={() => window.open("/mieszkania/c-1-1/", "_blank")}
          />
        </g>
        <g id="C_1_2">
          <polygon
            className={clsx("cls-1", getStatusClass(apartmentList, "c-1-2"))}
            points="991.94 122.92 984.67 206.18 971.14 205 970.57 206.26 954.09 205 954.2 203.78 949.18 203.09 947.27 219.67 911.9 216.82 911.74 215.52 909.52 215.07 917.04 129.09 930.32 130.54 930.89 123.53 917.64 122.19 918.44 113.03 954.82 116.13 954.89 114.93 967.66 116.04 967.62 117.32 969.38 117.56 969.11 120.67 991.94 122.92"
            onClick={() => window.open("/mieszkania/c-1-2/", "_blank")}
          />
        </g>
        <g id="C_1_3">
          <polygon
            className={clsx("cls-1", getStatusClass(apartmentList, "c-1-3"))}
            points="1077.29 287.74 1064.36 289.21 1063.32 278.7 1056.6 279.47 1057.92 289.94 964.73 300.51 960.26 262.28 989.65 258.68 989.34 253.62 987.98 253.33 984.68 226.18 985.93 225.76 985.93 223.49 1068.99 214.12 1071.42 234.1 1064.43 235.07 1065.28 241.05 1071.98 240.21 1072.09 241.78 1073.45 241.48 1074.83 254.47 1073.41 254.56 1077.29 287.74"
            onClick={() => window.open("/mieszkania/c-1-3/", "_blank")}
          />
        </g>
        <g id="C_1_4">
          <polygon
            className={clsx("cls-1", getStatusClass(apartmentList, "c-1-4"))}
            points="1085.92 364.18 1073.27 365.61 1071.85 355.06 1065.37 355.84 1066.6 366.36 973.34 376.88 968.88 337.4 987.97 334.98 984.4 303.6 985.92 303.41 985.65 300.93 1077.62 290.55 1079.87 310.61 1073.06 311.51 1073.97 317.51 1080.57 316.83 1081.03 320.96 1082.31 320.82 1083.75 333.56 1082.35 333.72 1085.92 364.18"
            onClick={() => window.open("/mieszkania/c-1-4/", "_blank")}
          />
        </g>
        <g id="C_1_5">
          <polygon
            className={clsx("cls-1", getStatusClass(apartmentList, "c-1-5"))}
            points="1092.09 418.95 1067.07 421.64 1068.35 433.76 1064.4 434.2 1065.5 443.88 1002.58 450.98 1002.29 448.5 1000.75 448.65 997.56 416.98 978.12 419.17 973.65 379.69 1066.75 369.18 1068.04 379.67 1074.52 378.79 1073.33 368.44 1082.8 367.37 1086.37 398.97 1089.8 398.59 1090.4 404.62 1091.75 404.38 1093.16 417.05 1091.78 417.29 1092.09 418.95"
            onClick={() => window.open("/mieszkania/c-1-5/", "_blank")}
          />
        </g>
        <g id="C_1_6">
          <polygon
            className={clsx("cls-1", getStatusClass(apartmentList, "c-1-6"))}
            points="1092.42 421.76 1071.4 424.13 1073.84 445.53 986.09 455.62 990.08 491.03 1082.04 480.72 1080.8 470.16 1087.36 469.54 1088.54 479.99 1098.85 478.82 1097.55 467.5 1098.85 467.33 1097.36 454.52 1096.08 454.6 1092.42 421.76"
            onClick={() => window.open("/mieszkania/c-1-6/", "_blank")}
          />
        </g>
        <g id="C_1_7">
          <polygon
            className={clsx("cls-1", getStatusClass(apartmentList, "c-1-7"))}
            points="1099.17 481.63 1084.39 483.3 1085.57 493.74 1079.28 494.55 1078.14 484 990.38 493.92 991.85 506.62 989.65 528.89 990.31 529.78 1076.92 520.25 1073.48 551.35 1093.12 553.34 1097.57 505.83 1098.93 506.06 1100.29 493.12 1099 493.05 1099.87 485.47 1099.17 481.63"
            onClick={() => window.open("/mieszkania/c-1-7/", "_blank")}
          />
        </g>
        <g id="C_1_8">
          <polygon
            className={clsx("cls-1", getStatusClass(apartmentList, "c-1-8"))}
            points="1072 540.63 989.23 532.2 982.46 600.87 1086.44 611.23 1088.17 597.81 1083.86 597.45 1084.66 589.57 1089.13 589.92 1089.39 588.22 1090.54 588.34 1091.89 575.63 1090.53 575.25 1092.61 555.86 1070.51 553.5 1072 540.63"
            onClick={() => window.open("/mieszkania/c-1-8/", "_blank")}
          />
        </g>
        <g id="C_1_9">
          <polygon
            className={clsx("cls-1", getStatusClass(apartmentList, "c-1-9"))}
            points="1081.93 613.93 983.48 603.89 982.89 609.63 981.35 609.49 977.78 644.86 979.28 644.96 978.12 656.43 976.57 656.3 972.42 697.2 973.95 697.37 973.72 699.54 1044.64 706.77 1044.26 707.88 1079.87 711.87 1079.96 710.36 1087.86 711.18 1092.25 668.06 1064.51 664.99 1064.86 662.44 1081.58 664.14 1081.73 662.89 1082.93 663 1084.29 650.14 1083.03 649.86 1085.21 628.37 1080.51 627.89 1081.93 613.93"
            onClick={() => window.open("/mieszkania/c-1-9/", "_blank")}
          />
        </g>
        <g id="C_1_10">
          <polygon
            className={clsx("cls-1", getStatusClass(apartmentList, "c-1-10"))}
            points="1095.06 668.35 1090.02 717.84 1099.2 718.77 1098.81 720.12 1134.34 724.01 1134.53 722.37 1156.97 724.65 1156.83 726.2 1192.12 729.79 1192.3 728.26 1196.38 728.66 1197.57 717 1199.07 717.21 1202.86 681.87 1201.17 681.68 1201.75 676.2 1222.37 678.47 1226.93 633.75 1147.99 625.68 1147.08 635.81 1136.45 634.55 1137.74 624.38 1110.65 621.62 1107.73 652.49 1106.47 652.47 1105.18 665.08 1106.49 664.93 1105.95 669.57 1095.06 668.35"
            onClick={() => window.open("/mieszkania/c-1-10/", "_blank")}
          />
        </g>
        <g id="C_1_11">
          <polygon
            className={clsx("cls-1", getStatusClass(apartmentList, "c-1-11"))}
            points="1114.3 575.83 1113.03 588.57 1114.22 588.7 1114.02 591.04 1120.72 591.72 1119.76 599.7 1113.21 598.94 1111.17 619.1 1204.64 628.62 1204.9 626.14 1206.43 626.26 1209.77 594.61 1230.68 596.74 1234.71 557.15 1138.06 547.36 1137 557.71 1130.74 557.18 1131.82 546.72 1122.11 545.73 1120.58 560.79 1117.14 560.44 1115.56 576.08 1114.3 575.83"
            onClick={() => window.open("/mieszkania/c-1-11/", "_blank")}
          />
        </g>
        <g id="C_1_12">
          <polygon
            className={clsx("cls-1", getStatusClass(apartmentList, "c-1-12"))}
            points="1121.75 471.32 1123.17 483.85 1124.46 483.99 1124.76 486.61 1131.22 486.13 1132.34 493.1 1126.69 494 1127.19 497.56 1125.29 514.54 1221.25 503.71 1220.96 501.23 1222.51 501.03 1218.9 469.4 1239.91 467.03 1235.45 427.48 1138.94 438.43 1140.19 448.73 1133.86 449.58 1132.68 439.14 1119.56 440.61 1123.04 471.26 1121.75 471.32"
            onClick={() => window.open("/mieszkania/c-1-12/", "_blank")}
          />
        </g>
        <g id="C_1_13">
          <polygon
            className={clsx("cls-1", getStatusClass(apartmentList, "c-1-13"))}
            points="1113.11 394.81 1114.54 407.55 1115.75 407.46 1116.09 409.79 1122.78 409.05 1123.72 416.67 1116.99 417.69 1119.25 437.82 1212.62 427.28 1212.35 424.8 1213.88 424.61 1210.22 392.98 1231.29 390.61 1226.82 351.06 1130.31 362.01 1131.64 372.58 1124.99 373.19 1123.81 362.74 1110.95 364.19 1114.48 394.77 1113.11 394.81"
            onClick={() => window.open("/mieszkania/c-1-13/", "_blank")}
          />
        </g>
        <g id="C_1_14">
          <polygon
            className={clsx("cls-1", getStatusClass(apartmentList, "c-1-14"))}
            points="1104.46 318.3 1105.97 331.16 1107.12 331.02 1110.62 361.39 1204 350.86 1203.72 348.37 1205.25 348.17 1201.66 316.55 1222.67 314.17 1218.19 274.53 1195.74 277.27 1121.68 285.57 1122.89 296.05 1116.36 296.75 1115.18 286.31 1102.32 287.76 1105.86 318.32 1104.46 318.3"
            onClick={() => window.open("/mieszkania/c-1-14/", "_blank")}
          />
        </g>
        <g id="C_1_15">
          <polygon
            className={clsx("cls-1", getStatusClass(apartmentList, "c-1-15"))}
            points="1095.89 242.02 1097.34 254.74 1098.59 254.71 1098.85 256.93 1105.54 256.19 1106.47 263.81 1099.47 264.91 1102.01 284.96 1195.38 274.42 1195.09 271.94 1196.64 271.75 1192.97 240.13 1214.04 237.75 1209.57 198.1 1113.07 209.15 1114.24 219.59 1107.99 220.3 1106.83 209.86 1097.14 210.94 1098.83 225.98 1095.4 226.37 1097.14 241.83 1095.89 242.02"
            onClick={() => window.open("/mieszkania/c-1-15/", "_blank")}
          />
        </g>
        <g id="C_1_16">
          <polygon
            className={clsx("cls-1", getStatusClass(apartmentList, "c-1-16"))}
            points="1086.88 162.35 1088.31 175 1089.61 175.1 1089.73 176.17 1094.45 175.64 1096.43 193.23 1091.71 193.75 1093.16 208.81 1195.67 197 1195.39 194.51 1196.92 194.32 1193.54 164.3 1192 164.45 1191.87 163.34 1182.96 164.36 1181.37 150.35 1088.01 160.9 1088.18 162.36 1086.88 162.35"
            onClick={() => window.open("/mieszkania/c-1-16/", "_blank")}
          />
        </g>
      </svg>
    </>
  )
}

export default BuildingFloorGroundLow
