import { getStatus } from "./getStatus"

export const getStatusClass = (apartments, slug) => {
  const status = getStatus(apartments, slug)

  switch (status) {
    case "available":
      return "status-green"
    case "reserved":
      return "status-yellow"
    case "sold":
      return "status-red"
    default:
      return undefined
  }
}
