import "./styles.scss"

import React from "react"
import clsx from "clsx"

import Button from "components/Button"

const Switch = ({ view, setView }) => {
  return (
    <div className="apartments-switch">
      <Button
        className={clsx(
          "button--small",
          view !== "listing" && "button--bordered"
        )}
        onClick={() => setView("listing")}
      >
        Lista mieszkań
      </Button>
      <Button
        className={clsx(
          "button--small",
          view !== "building" && "button--bordered"
        )}
        onClick={() => setView("building")}
      >
        Widok budynku
      </Button>
    </div>
  )
}

export default Switch
