import React from "react"
import clsx from "clsx"

import { getStatusClass } from "../../utils"

const BuildingFloorGroundHigh = ({ apartmentList }) => {
  return (
    <>
      <img
        src={require("assets/images/building/lobzow-floor-gh.png").default}
        alt=""
      />
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="1318"
        height="740"
        viewBox="0 0 1318 740"
      >
        <g id="A_2_1">
          <polygon
            className={clsx("cls-1", getStatusClass(apartmentList, "a-2-1"))}
            points="191.76 60.36 193.83 48.12 192.69 47.81 196.73 23.68 192.11 22.9 192.4 20.99 169.91 17.57 169.2 19 167.42 18.69 166.56 23.74 154.74 21.72 155.85 15.09 143.93 13.17 143.65 14.65 123.59 11.09 114.96 40.21 116.37 40.73 105.64 76.95 104.18 76.54 97.16 100.56 98.39 101.38 94.43 114.74 109.76 119.29 109.35 120.73 120.59 123.82 121.37 122.72 145.39 129.85 144.91 131.24 169.36 138.52 169.81 137.08 177.06 139.23 190.49 60.37 191.76 60.36"
            onClick={() => window.open("/mieszkania/a-2-1/", "_blank")}
          />
        </g>
        <g id="A_2_2">
          <polygon
            className={clsx("cls-1", getStatusClass(apartmentList, "a-2-2"))}
            points="241.73 72.29 229.39 70.15 228.97 71.36 196.88 65.9 194.91 77.46 190.29 76.42 179.71 140.01 183.12 141.03 182.56 142.39 205.53 149.15 206.35 147.92 219.88 151.92 219.45 153.48 243.86 160.61 244.31 159.17 249.43 160.69 261.19 91.53 256.23 90.43 258.71 76.41 241.42 73.47 241.73 72.29"
            onClick={() => window.open("/mieszkania/a-2-2/", "_blank")}
          />
        </g>
        <g id="A_2_3">
          <polygon
            className={clsx("cls-1", getStatusClass(apartmentList, "a-2-3"))}
            points="315.8 81.22 303.25 78.92 303.01 80.28 300.94 79.86 300.37 83.17 296.22 82.29 296.5 79.17 266.94 74.15 264.75 87.07 276.26 89.03 275.2 95.19 263.65 93.47 252.43 159.47 254.82 159.88 254.6 161.37 289.1 167.23 289.36 165.75 296.97 167.04 296.74 168.54 322.19 172.86 322.46 171.38 328.59 172.42 334.63 85.66 315.31 82.51 315.8 81.22"
            onClick={() => window.open("/mieszkania/a-2-3/", "_blank")}
          />
        </g>
        <g id="A_2_4">
          <polygon
            className={clsx("cls-1", getStatusClass(apartmentList, "a-2-4"))}
            points="335.15 81.6 337.26 69.2 348.13 70.38 412.38 74.78 411.31 90.23 402.96 89.65 402.5 96.26 410.85 96.84 408.8 126 381.06 124.18 381.14 122.56 369.29 122 368.12 144.24 355.29 158.49 355.21 160.08 334.71 158.37 334.87 156.91 332.44 156.73 336.93 91.01 346.21 91.9 346.54 87.23 347.32 86.95 347.27 85.78 337.42 85.09 337.54 83.36 336.15 83.12 336.1 81.98 335.15 81.6"
            onClick={() => window.open("/mieszkania/a-2-4/", "_blank")}
          />
        </g>
        <g id="B_2_1">
          <path
            className={clsx("cls-1", getStatusClass(apartmentList, "b-2-1"))}
            d="m575.71,104.42l-17.67-1.23-2.84-.2-80.91-5.62-1.27,18.31-1.51-.08-.91,14.38,1.39.53-.11,1.49-1.51-.08-2.59,37.33,82.74,5.78,2.42-35.72,20.25,1.5.12-1.88h1.25s.89-12.66.89-12.66l-1.25-.18,1.51-21.67Zm-14.92,20.25l-6.57-.72.83-11.15,2.04.1-.02.24,4.5.32-.78,11.21Z"
            onClick={() => window.open("/mieszkania/b-2-1/", "_blank")}
          />
        </g>
        <g id="B_2_2">
          <polygon
            className={clsx("cls-1", getStatusClass(apartmentList, "b-2-2"))}
            points="554.63 152.03 552.84 177.7 469.42 171.9 468.38 182.47 466.83 182.8 465.2 206.71 551.65 212.67 552.35 202.61 558.46 203.13 558.01 213.11 568.1 213.81 568.74 202.63 570.12 202.65 571.01 190.18 569.66 190.24 572.99 143.54 562.47 142.82 561.54 152.52 554.63 152.03"
            onClick={() => window.open("/mieszkania/b-2-2/", "_blank")}
          />
        </g>
        <g id="B_2_3">
          <polygon
            className={clsx("cls-1", getStatusClass(apartmentList, "b-2-3"))}
            points="568.36 228.15 567.49 240.6 566.25 240.6 563.02 286.83 552.5 286.1 553.19 276.12 546.04 275.44 547.74 249.79 462.59 244.02 464.27 219.76 465.77 219.84 466.49 209.5 551.5 215.25 550.65 225.69 558.24 226.1 558.78 215.85 568.15 216.79 567.12 228.01 568.36 228.15"
            onClick={() => window.open("/mieszkania/b-2-3/", "_blank")}
          />
        </g>
        <g id="B_2_4">
          <path
            className={clsx("cls-1", getStatusClass(apartmentList, "b-2-4"))}
            d="m562.73,290.95l.11-1.38-20.42-1.31,2.7-35.73-82.5-5.57-1.9,24.17,1.48.05-.91,13.09,10.66.6-.44,5.42-1.51-.08-1.15,17.11,1.48.04-.86,12.28,87.47,6.08s.78-14.96,1.02-14.7c.24.26,3.37.22,3.37.22l.57-7.69,1.21.04.88-12.54-1.26-.1Zm-15.07,20.97l-6.29-.33.59-8.29,6.34.46-.64,8.16Z"
            onClick={() => window.open("/mieszkania/b-2-4/", "_blank")}
          />
        </g>
        <g id="B_2_5">
          <polygon
            className={clsx("cls-1", getStatusClass(apartmentList, "b-2-5"))}
            points="560.38 328.47 466.32 322.14 459.12 345.64 460.57 346.1 458.32 353.38 537.22 377.71 540.29 367.54 546.58 369.6 543.48 379.64 555.71 383.4 556.39 380.5 557.63 364.5 558.87 364.66 559.74 352.05 558.49 352.05 558.59 350.57 553.15 348.63 556.41 338.57 559.36 339.48 560.38 328.47"
            onClick={() => window.open("/mieszkania/b-2-5/", "_blank")}
          />
        </g>
        <g id="B_2_6">
          <polygon
            className={clsx("cls-1", getStatusClass(apartmentList, "b-2-6"))}
            points="554.91 386.02 457.51 356.01 456.1 360.61 454.55 360.15 447.84 382.03 449.21 382.93 447.28 389.19 445.63 389.15 438.68 412.01 440.12 412.47 436.26 425 515.5 449.43 518.57 439.63 525.8 441.88 522.78 451.66 533.65 455.02 542.44 426.57 543.58 426.92 547.32 414.89 546.21 414.51 547.36 410.37 541.07 408.69 542.85 402.9 549.1 404.88 554.91 386.02"
            onClick={() => window.open("/mieszkania/b-2-6/", "_blank")}
          />
        </g>
        <g id="B_2_7">
          <polygon
            className={clsx("cls-1", getStatusClass(apartmentList, "b-2-7"))}
            points="536.77 481.34 517.72 543.16 507.94 540.15 507.15 541.23 474.37 531.4 474.88 529.96 459.61 525.22 458.19 530.25 459.44 530.37 456.78 539.61 405.66 524.31 411.97 503.78 410.6 502.88 417.27 480.99 418.85 481.46 424.94 460.86 423.71 460.2 430.7 437.96 432.13 438.37 435.44 427.63 498.39 447.01 496.32 453.82 505.47 456.51 507.65 449.86 532.84 457.62 532.51 458.27 533.85 458.63 530.19 470.5 528.94 470.27 526.25 478.29 536.77 481.34"
            onClick={() => window.open("/mieszkania/b-2-7/", "_blank")}
          />
        </g>
        <g id="B_2_8">
          <polygon
            className={clsx("cls-1", getStatusClass(apartmentList, "b-2-8"))}
            points="550.95 476.9 554.6 465.03 555.79 465.34 556.09 464.79 584.29 473.5 582.25 480.06 588.47 482.08 590.49 475.4 653.4 494.8 651.74 500.16 653.17 500.62 642.99 533.64 641.55 533.22 637.24 547.19 638.68 547.66 628.49 580.68 627.05 580.25 624.51 588.49 574.59 573.17 578.71 561.61 563.21 557.17 562.82 558.6 529.55 548.36 530.13 546.99 520.35 543.97 539.33 482.38 549.82 485.71 552.31 477.2 550.95 476.9"
            onClick={() => window.open("/mieszkania/b-2-8/", "_blank")}
          />
        </g>
        <g id="B_2_9">
          <polygon
            className={clsx("cls-1", getStatusClass(apartmentList, "b-2-9"))}
            points="675.46 423.18 676.81 423.57 658.18 484.74 656.63 484.29 654.2 492.16 556.81 462.16 560.05 451.65 564.41 452.73 567.79 443.06 563.21 441.38 565.61 433.58 564.44 433.14 568.11 421.22 569.26 421.74 573.12 409.22 584.45 410.01 583.85 420.15 590.32 420.73 590.95 410.46 677.52 416.49 675.46 423.18"
            onClick={() => window.open("/mieszkania/b-2-9/", "_blank")}
          />
        </g>
        <g id="B_2_10">
          <path
            className={clsx("cls-1", getStatusClass(apartmentList, "b-2-10"))}
            d="m673.94,341.12l-89.96-6.25-1.36,19.68,6.49.37-.37,6.07-6.54-.46-.29,4.16-1.24-.17-.87,12.53h1.26l-.69,10.14-1.77,4.77,2.99,1.3-4.26,13.81s13.97.79,14.24.69.71-10.22.71-10.22l6.46.41-.81,10.24,91.47,6.34,2.43-38.99-18.85-1.29,2.27-30.66-1.47-.04.17-2.43Z"
            onClick={() => window.open("/mieszkania/b-2-10/", "_blank")}
          />
        </g>
        <g id="B_2_11">
          <polygon
            className={clsx("cls-1", getStatusClass(apartmentList, "b-2-11"))}
            points="589.17 260.1 679.13 266.35 678.96 268.79 680.47 268.92 678.29 299.85 696.96 301.15 694.32 339.76 603.1 333.44 603.58 322.97 597.46 322.77 596.75 332.99 584.17 332.12 586.33 302.08 584.99 302.22 585.86 289.68 587.11 289.94 587.16 285.51 593.93 286.22 594.4 280.5 587.81 279.78 589.17 260.1"
            onClick={() => window.open("/mieszkania/b-2-11/", "_blank")}
          />
        </g>
        <g id="B_2_12">
          <polygon
            className={clsx("cls-1", getStatusClass(apartmentList, "b-2-12"))}
            points="591.24 212.32 590.4 224.72 591.65 224.7 590.38 242.65 593.75 242.89 592.72 257.59 601.95 258.24 602.66 248.02 609.07 248.51 608.31 258.67 699.51 265.01 702.08 227.58 673.1 225.38 673.55 220.46 675.02 220.62 676.94 193.49 675.44 193.43 675.62 191 597.73 185.58 596.37 205.26 599.64 205.75 598.9 211.19 592.35 210.74 592.46 212.36 591.24 212.32"
            onClick={() => window.open("/mieszkania/b-2-12/", "_blank")}
          />
        </g>
        <g id="B_2_13">
          <polygon
            className={clsx("cls-1", getStatusClass(apartmentList, "b-2-13"))}
            points="723.96 91.63 711.23 90.8 711.24 92.11 709.76 91.88 709.11 96.44 686.85 94.4 679.78 175.28 694.21 176.54 694.03 178 712.34 179.4 712.88 178.16 718.05 178.62 716.74 193.55 718.23 193.68 718.13 195.17 747.37 197.58 747.97 196.28 750.39 196.5 757.66 113.38 730.39 110.93 731.85 97.4 727.55 97.01 727.87 93.47 723.43 93.1 723.96 91.63"
            onClick={() => window.open("/mieszkania/b-2-13/", "_blank")}
          />
        </g>
        <g id="B_2_14">
          <path
            className={clsx("cls-1", getStatusClass(apartmentList, "b-2-14"))}
            d="m831.24,132.21l4-45.78-2.06-.18.12-1.5-23.09-2.17-.4,1.37-4.67-.41.07-3.5-6.54-.57-.31-1.61-11.87-1.07.11,1.63-9.45-.81.15-1.47-12.2-.84-.04,1.48-13.95-.98.08-1.51-12.05-.83-.05,1.51-2.51-.18-.16,1.35-1.22.08-1.07,12.31,1.25.18-1.75,19.8,10.09.89.67-4.83,16.43,1.27-8.38,97.07,33.79,2.98.14-1.49,4.91.66-.31,6.52,32.03,2.65-.13-1.38,1.31.12,5.96-66.93-4.47-.4,1.1-13.99,4.47.56Zm-43.24-27.5l-11.7-1.1.52-6.7,11.76,1.27-.58,6.53Zm12.99,40.79l-5.75-.5.74-8.08,5.65.5-.64,8.08Z"
            onClick={() => window.open("/mieszkania/b-2-14/", "_blank")}
          />
        </g>
        <g id="C_2_1">
          <path
            className={clsx("cls-1", getStatusClass(apartmentList, "c-2-1"))}
            d="m937.92,92.99l.32-1.58-8.39-.79.02-1.21-11.59-1.11-.16,1.6-9.33-.8.1-1.51-11.61-.93-.56,1.38-9.33-.82.1-1.51-12.1-1.02-.3,1.21-6.43-.32-.56,3.46-4.66-.39.11-1.27-23.67-1.79.15,1.26-2.05-.19-11.07,126.54,1.05.35-.02,1.75,70.31,5.73,9.75-103.5,16.62,1.9-.43,5.1,10.81.96,1.63-20,1.38.02,1.07-12.37-1.16-.15Zm-66.71,53.68l-6.17-.62.88-9.26,6.15.53-.86,9.35Zm5.59-34.11l-11.91-1.13.44-6.52,12.08,1.08-.61,6.57Z"
            onClick={() => window.open("/mieszkania/c-2-1/", "_blank")}
          />
        </g>
        <g id="C_2_2">
          <polygon
            className={clsx("cls-1", getStatusClass(apartmentList, "c-2-2"))}
            points="960.76 110.56 948.14 109.84 947.91 111.02 943.1 111.01 942.85 113.95 938.78 113.84 937.23 128.66 909.75 126.04 902.44 209.78 904.88 209.99 904.74 211.6 939.61 214.54 941.08 198.11 946.24 198.56 946.05 200.02 962.4 201.44 962.48 199.97 975.66 201.12 982.88 118.51 961.74 116.48 961.86 112.28 960.22 112.15 960.76 110.56"
            onClick={() => window.open("/mieszkania/c-2-2/", "_blank")}
          />
        </g>
        <g id="C_2_3">
          <polygon
            className={clsx("cls-1", getStatusClass(apartmentList, "c-2-3"))}
            points="1063.5 248.02 1062.16 235.52 1060.84 235.66 1060.88 233.85 1054.18 235.16 1053.46 229.46 1060.02 228.47 1057.81 208.85 976.89 217.99 977.17 220.41 975.67 220.61 978.45 247.52 980.21 247.35 980.88 252.24 951.73 255.74 956.36 292.51 1047.04 282.72 1046.11 272.29 1052.22 271.83 1053.37 282.01 1065.9 280.59 1062.04 248.15 1063.5 248.02"
            onClick={() => window.open("/mieszkania/c-2-3/", "_blank")}
          />
        </g>
        <g id="C_2_4">
          <polygon
            className={clsx("cls-1", getStatusClass(apartmentList, "c-2-4"))}
            points="1072.22 325.31 1070.79 312.74 1069.54 312.8 1069.06 308.64 1062.58 309.63 1062.15 303.55 1068.64 302.56 1066.22 283.33 976.8 293.16 976.88 295.86 975.13 296.08 978.9 326.87 960.27 328.97 964.81 366.95 1055.64 356.78 1054.52 346.75 1060.63 346.3 1061.78 356.48 1074.68 354.88 1070.75 325.28 1072.22 325.31"
            onClick={() => window.open("/mieszkania/c-2-4/", "_blank")}
          />
        </g>
        <g id="C_2_5">
          <polygon
            className={clsx("cls-1", getStatusClass(apartmentList, "c-2-5"))}
            points="1081.38 406.56 1079.95 394.19 1078.72 394.22 1077.84 388.32 1074.96 388.68 1071.27 358.17 1061.59 359.02 1063.23 369.38 1057.16 370.19 1055.75 359.91 965.19 369.88 969.28 408.64 987.89 406.54 991.22 437.37 992.83 437.22 993.11 439.63 1054.42 432.72 1053.34 423.28 1057.47 423.07 1056.08 411.41 1080.32 408.43 1079.81 406.59 1081.38 406.56"
            onClick={() => window.open("/mieszkania/c-2-5/", "_blank")}
          />
        </g>
        <g id="C_2_6">
          <polygon
            className={clsx("cls-1", getStatusClass(apartmentList, "c-2-6"))}
            points="1080.64 411.16 1060.17 413.48 1062.54 434.32 976.88 444.26 980.95 478.79 1070.53 468.61 1069.33 458.5 1075.71 457.72 1076.86 467.9 1086.91 466.76 1085.57 455.62 1086.89 455.48 1085.35 442.93 1084.24 443.06 1080.64 411.16"
            onClick={() => window.open("/mieszkania/c-2-6/", "_blank")}
          />
        </g>
        <g id="C_2_7">
          <polygon
            className={clsx("cls-1", getStatusClass(apartmentList, "c-2-7"))}
            points="1088.31 480.69 1087.05 493.12 1085.77 493.29 1081.33 539.37 1062.19 537.42 1065.64 506.69 981.86 516.31 980.37 516.21 982.59 494.54 981.36 481.88 1066.57 471.91 1067.83 482.24 1073.96 481.27 1072.94 471.16 1087.22 469.49 1087.74 474.04 1087.1 480.6 1088.31 480.69"
            onClick={() => window.open("/mieszkania/c-2-7/", "_blank")}
          />
        </g>
        <g id="C_2_8">
          <polygon
            className={clsx("cls-1", getStatusClass(apartmentList, "c-2-8"))}
            points="1078.87 573.35 1080.36 560.77 1079.16 560.68 1080.83 541.83 1059.72 539.5 1060.75 526.98 979.98 518.77 973.16 585.7 1075.29 596.13 1076.64 582.96 1072.17 582.5 1072.86 574.21 1077.44 575 1077.52 572.93 1078.87 573.35"
            onClick={() => window.open("/mieszkania/c-2-8/", "_blank")}
          />
        </g>
        <g id="C_2_9">
          <polygon
            className={clsx("cls-1", getStatusClass(apartmentList, "c-2-9"))}
            points="1071.47 646.04 1072.72 633.61 1071.56 633.52 1073.62 612.47 1069.04 612 1070.42 598.4 974.27 588.34 973.7 593.93 972.42 594.08 968.63 628.42 970.41 628.63 969.05 639.54 967.62 639.67 963.61 679.51 965.22 679.69 964.99 681.81 1034.09 688.85 1033.95 690.36 1067.9 693.89 1068.51 692.36 1076.2 693.15 1080.48 651.14 1069.96 649.81 1070.28 646.11 1071.47 646.04"
            onClick={() => window.open("/mieszkania/c-2-9/", "_blank")}
          />
        </g>
        <g id="C_2_10">
          <polygon
            className={clsx("cls-1", getStatusClass(apartmentList, "c-2-10"))}
            points="1094.39 635.85 1093.09 648.25 1094.35 648.29 1093.67 652.24 1083.22 651.42 1078.3 699.64 1087.25 700.55 1087.1 702.15 1121.5 705.55 1121.67 704.06 1143.53 706.28 1143.2 707.78 1177.37 711.15 1177.95 709.79 1182.22 709.74 1183.1 698.82 1184.71 698.54 1188.08 664.36 1186.6 664.41 1187.12 659.34 1207.21 660.86 1211.68 617.63 1134.53 609.82 1133.57 619.31 1123.55 618.49 1124.74 608.5 1098.41 605.9 1095.66 636.11 1094.39 635.85"
            onClick={() => window.open("/mieszkania/c-2-10/", "_blank")}
          />
        </g>
        <g id="C_2_11">
          <polygon
            className={clsx("cls-1", getStatusClass(apartmentList, "c-2-11"))}
            points="1101.95 561.14 1100.69 573.5 1098.66 603.45 1190.25 612.6 1190.46 610.56 1192.15 610.47 1194.85 579.56 1215.35 581.65 1219.29 542.88 1109.35 531.7 1107.86 546.35 1104.34 546.04 1103.18 561.23 1101.95 561.14"
            onClick={() => window.open("/mieszkania/c-2-11/", "_blank")}
          />
        </g>
        <g id="C_2_12">
          <polygon
            className={clsx("cls-1", getStatusClass(apartmentList, "c-2-12"))}
            points="1109.21 459.45 1110.65 471.82 1111.93 471.54 1112.15 474.35 1118.67 473.61 1119.54 480.68 1114.03 481.55 1114.39 484.71 1112.67 501.56 1206.16 491.01 1205.89 488.6 1207.29 487.99 1203.88 457.59 1224.35 455.27 1219.99 416.61 1125.62 427.2 1126.83 437.29 1121.02 438.27 1119.87 428.09 1107.09 429.54 1110.82 459.46 1109.21 459.45"
            onClick={() => window.open("/mieszkania/c-2-12/", "_blank")}
          />
        </g>
        <g id="C_2_13">
          <polygon
            className={clsx("cls-1", getStatusClass(apartmentList, "c-2-13"))}
            points="1098.7 355.08 1102.17 384.74 1100.81 384.99 1102.2 397.32 1103.46 397.34 1103.71 399.5 1110.22 398.78 1111.14 406.21 1104.58 407.2 1106.79 426.81 1197.76 416.54 1197.49 414.12 1198.97 414.11 1195.47 383.13 1215.94 380.81 1211.58 342.14 1117.56 352.95 1118.42 362.82 1112.38 363.84 1111.23 353.66 1098.7 355.08"
            onClick={() => window.open("/mieszkania/c-2-13/", "_blank")}
          />
        </g>
        <g id="C_2_14">
          <polygon
            className={clsx("cls-1", getStatusClass(apartmentList, "c-2-14"))}
            points="1092.42 310.5 1093.81 322.85 1094.97 322.76 1095.3 325.04 1101.82 324.3 1102.73 331.75 1096.17 332.74 1098.13 352.23 1189.36 342.08 1189.08 339.66 1190.64 338.99 1187.08 308.65 1207.55 306.34 1202.82 267.66 1109.15 278.48 1110.33 288.67 1103.97 289.37 1102.82 279.19 1090.29 280.6 1093.67 310.45 1092.42 310.5"
            onClick={() => window.open("/mieszkania/c-2-14/", "_blank")}
          />
        </g>
        <g id="C_2_15">
          <path
            className={clsx("cls-1", getStatusClass(apartmentList, "c-2-15"))}
            d="m1084.02,235.87l1.37,12.39,1.32-.11.19,2.42,6.52-.73.91,7.43-6.83.78,2.49,19.83,90.97-10.27-.28-2.42,1.93-.24-3.94-30.76,20.48-2.31-4.35-38.53s-93.82,10.26-94.04,10.67,1.02,10.22,1.02,10.22l-5.96.64-1.14-10.18-9.44,1.06,1.65,14.65-3.34.38,1.94,15.25-1.47-.17Z"
            onClick={() => window.open("/mieszkania/c-2-15/", "_blank")}
          />
        </g>
        <g id="C_2_16">
          <polygon
            className={clsx("cls-1", getStatusClass(apartmentList, "c-2-16"))}
            points="1076.34 157 1167.31 146.73 1169.05 159.87 1177.55 159.38 1177.66 160.46 1179.32 160.29 1182.46 189.66 1180.97 189.75 1181.25 192.17 1081.37 203.69 1079.68 188.8 1084.55 188.5 1082.62 171.37 1078.02 171.88 1077.85 170.73 1076.66 170.89 1075.23 158.27 1076.48 158.2 1076.34 157"
            onClick={() => window.open("/mieszkania/c-2-16/", "_blank")}
          />
        </g>
      </svg>
    </>
  )
}

export default BuildingFloorGroundHigh
