import "./styles.scss"

import React from "react"
import { Link } from "gatsby"

const Breadcrumbs = ({ title, parent }) => {
  return (
    <div className="breadcrumbs">
      <div className="container-fluid">
        <div className="breadcrumbs__wrapper">
          <Link to="/">Strona główna</Link>
          <span>/</span>
          {parent && (
            <>
              <Link to={parent.url}>{parent.title}</Link>
              <span>/</span>
            </>
          )}
          <p>{title}</p>
        </div>
      </div>
    </div>
  )
}

export default Breadcrumbs
