import React from "react"
import clsx from "clsx"

import { getStatusClass } from "../../utils"

const BuildingFloor3 = ({ apartmentList }) => {
  return (
    <>
      <img
        src={require("assets/images/building/lobzow-floor-3.png").default}
        alt=""
      />
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="1318"
        height="740"
        viewBox="0 0 1318 740"
      >
        <g id="A_5_1">
          <polygon
            className={clsx("cls-1", getStatusClass(apartmentList, "a-5-1"))}
            points="199.43 62.88 201.5 50.64 200.36 50.34 204.4 26.2 199.78 25.42 200.07 23.52 177.58 20.09 176.87 21.52 175.09 21.22 174.23 26.27 162.41 24.24 163.52 17.61 151.6 15.69 151.32 17.17 131.26 13.62 122.63 42.74 124.04 43.25 113.31 79.47 111.85 79.06 104.83 103.08 106.06 103.9 102.1 117.26 117.43 121.81 117.02 123.25 128.26 126.34 129.04 125.25 153.06 132.37 152.58 133.76 177.03 141.04 177.48 139.6 184.73 141.76 198.16 62.9 199.43 62.88"
            onClick={() => window.open("/mieszkania/a-5-1/", "_blank")}
          />
        </g>
        <g id="A_5_2">
          <polygon
            className={clsx("cls-1", getStatusClass(apartmentList, "a-5-2"))}
            points="249.27 75.08 236.92 72.94 236.5 74.14 204.42 68.68 202.45 80.25 197.82 79.21 187.25 142.8 190.66 143.82 190.09 145.18 213.06 151.93 213.88 150.7 227.42 154.71 226.98 156.27 251.4 163.39 251.85 161.95 256.96 163.47 268.73 94.31 263.77 93.22 266.24 79.19 248.95 76.26 249.27 75.08"
            onClick={() => window.open("/mieszkania/a-5-2/", "_blank")}
          />
        </g>
        <g id="A_5_3">
          <polygon
            className={clsx("cls-1", getStatusClass(apartmentList, "a-5-3"))}
            points="323.33 84 310.79 81.7 310.55 83.06 308.47 82.64 307.91 85.96 303.75 85.07 304.03 81.95 274.48 76.93 272.28 89.86 283.79 91.82 282.74 97.98 271.18 96.26 259.96 162.26 262.35 162.67 262.13 164.16 296.63 170.02 296.89 168.54 304.51 169.83 304.27 171.32 329.73 175.65 329.99 174.17 336.13 175.21 342.17 88.44 322.84 85.3 323.33 84"
            onClick={() => window.open("/mieszkania/a-5-3/", "_blank")}
          />
        </g>
        <g id="A_5_4">
          <polygon
            className={clsx("cls-1", getStatusClass(apartmentList, "a-5-4"))}
            points="328.72 69.35 326.66 81.4 327.89 81.66 327.64 83.14 344.74 86.3 344.88 87.81 353.79 88.68 353.52 97.59 344.26 96.75 339.92 159.15 342.34 159.32 342.03 160.31 362.17 161.97 362.68 160.9 375.45 146.71 377.25 124.42 388.18 125.11 388.07 126.56 415.54 128.23 418 99.51 409.68 98.93 410.03 92.01 423.61 93.29 423.95 94.58 443.25 95.96 445.67 56.67 432.47 56.01 432.56 54.39 409.26 52.91 409.26 54.39 381.87 52.48 381.98 50.73 369.97 50.15 369.84 51.65 359.55 50.93 359.64 49.32 347.34 48.89 347.28 49.83 338.29 49.44 335.3 68.79 330.27 67.93 329.94 69.66 328.72 69.35"
            onClick={() => window.open("/mieszkania/a-5-4/", "_blank")}
          />
        </g>
        <g id="B_5_1">
          <path
            className={clsx("cls-1", getStatusClass(apartmentList, "b-5-1"))}
            d="m639.95,70.43l.09-1.48-22.75-1.32-.54,1.18-27.55-1.9-.34-1.29-11.57-1.04-.12,1.48-9.81-.68.08-1.49-23.23-1.59-.04,1.47-8.82-.62.09-1.48-23.24-1.61-.04,1.49-4.34-.3.09-1.62-23.26-1.48-.27,1.21-8.83-.36.09-1.6-23.22-2.01-.28,1.74-3.7-.02-2.7,39.23,30.42,2.54.3-1.92,108.64,7.55-.93,13.15,1.61.12-.03,1.25,12.19.84-.18-1.26,15.33,1.07,1.08-13.76,29.42,1.79,2.57-36.84-6.21-.44Zm-104.86,19.45l-9.02-.77.85-11.52,8.99.63-.82,11.66Zm65.34.11l-6.15-.28.89-8.98,6.02.39-.76,8.87Z"
            onClick={() => window.open("/mieszkania/b-5-1/", "_blank")}
          />
        </g>
        <g id="B_5_2">
          <path
            className={clsx("cls-1", getStatusClass(apartmentList, "b-5-2"))}
            d="m582.24,106.88l-17.67-1.23-2.84-.2-80.91-5.62-1.27,18.31-1.51-.08-.91,14.38,1.39.53-.11,1.49-1.51-.08-2.59,37.33,82.74,5.78,2.42-35.72,20.25,1.5.12-1.88h1.25s.89-12.66.89-12.66l-1.25-.18,1.51-21.67Zm-14.92,20.25l-6.57-.72.83-11.15,2.04.1-.02.24,4.5.32-.78,11.21Z"
            onClick={() => window.open("/mieszkania/b-5-2/", "_blank")}
          />
        </g>
        <g id="B_5_3">
          <polygon
            className={clsx("cls-1", getStatusClass(apartmentList, "b-5-3"))}
            points="561.16 154.49 559.37 180.16 475.95 174.35 474.91 184.93 473.36 185.26 471.74 209.17 558.18 215.13 558.88 205.07 564.99 205.59 564.54 215.57 574.63 216.27 575.28 205.09 576.65 205.11 577.54 192.64 576.19 192.7 579.52 146 569 145.27 568.07 154.98 561.16 154.49"
            onClick={() => window.open("/mieszkania/b-5-3/", "_blank")}
          />
        </g>
        <g id="B_5_4">
          <polygon
            className={clsx("cls-1", getStatusClass(apartmentList, "b-5-4"))}
            points="574.89 230.33 574.02 242.79 572.78 242.79 569.55 289.01 559.03 288.28 559.72 278.3 552.57 277.62 554.27 251.97 469.12 246.2 470.8 221.94 472.3 222.02 473.02 211.68 558.05 217.43 557.26 227.87 564.71 228.18 565.65 218.07 574.68 218.97 573.65 230.19 574.89 230.33"
            onClick={() => window.open("/mieszkania/b-5-4/", "_blank")}
          />
        </g>
        <g id="B_5_5">
          <path
            className={clsx("cls-1", getStatusClass(apartmentList, "b-5-5"))}
            d="m569.26,293.04l.11-1.38-20.42-1.31,2.71-35.73-82.51-5.57-1.89,24.17,1.48.05-.92,13.09,10.66.6-.44,5.42-1.51-.08-1.15,17.11,1.49.04-.86,12.28,87.46,6.08s.78-14.96,1.02-14.7,3.37.22,3.37.22l.57-7.69,1.22.04.87-12.54-1.26-.1Zm-15.04,20.74l-6.39-.42.66-8.25,6.43.51-.7,8.16Z"
            onClick={() => window.open("/mieszkania/b-5-5/", "_blank")}
          />
        </g>
        <g id="B_5_6">
          <polygon
            className={clsx("cls-1", getStatusClass(apartmentList, "b-5-6"))}
            points="566.91 330.48 472.85 324.15 465.66 347.65 467.1 348.11 464.85 355.4 543.75 379.72 546.82 369.56 553.11 371.62 550.02 381.65 562.24 385.42 562.92 382.51 564.16 366.51 565.4 366.67 566.27 354.06 565.02 354.06 565.12 352.58 559.68 350.64 562.94 340.58 565.89 341.49 566.91 330.48"
            onClick={() => window.open("/mieszkania/b-5-6/", "_blank")}
          />
        </g>
        <g id="B_5_7">
          <polygon
            className={clsx("cls-1", getStatusClass(apartmentList, "b-5-7"))}
            points="561.49 387.29 464.1 357.29 462.68 361.89 461.14 361.43 454.42 383.3 455.8 384.2 453.87 390.47 452.22 390.43 445.26 413.28 446.7 413.74 442.84 426.27 522.09 450.7 525.15 440.91 532.39 443.15 529.37 452.94 540.24 456.29 549.03 427.85 550.16 428.19 553.9 416.16 552.79 415.78 553.94 411.64 547.65 409.96 549.44 404.17 555.69 406.16 561.49 387.29"
            onClick={() => window.open("/mieszkania/b-5-7/", "_blank")}
          />
        </g>
        <g id="B_5_8">
          <polygon
            className={clsx("cls-1", getStatusClass(apartmentList, "b-5-8"))}
            points="543.38 482.43 524.33 544.25 514.55 541.24 513.76 542.32 480.98 532.49 481.49 531.05 466.22 526.31 464.8 531.34 466.05 531.46 463.39 540.7 412.27 525.4 418.58 504.87 417.21 503.97 423.88 482.08 425.46 482.55 431.55 461.95 430.32 461.29 437.31 439.05 438.74 439.46 442.05 428.72 505 448.1 502.93 454.91 509.14 456.77 511.32 450.12 539.45 458.71 539.12 459.36 540.46 459.72 536.8 471.59 535.55 471.36 532.86 479.38 543.38 482.43"
            onClick={() => window.open("/mieszkania/b-5-8/", "_blank")}
          />
        </g>
        <g id="B_5_9">
          <polygon
            className={clsx("cls-1", getStatusClass(apartmentList, "b-5-9"))}
            points="557.44 478.1 561.09 466.23 562.28 466.55 562.58 465.99 590.78 474.7 588.74 481.26 594.95 483.28 596.98 476.6 659.88 496 658.23 501.36 659.66 501.83 649.48 534.84 648.04 534.42 643.73 548.39 645.17 548.86 634.98 581.88 633.54 581.45 631 589.69 581.08 574.37 585.2 562.81 569.69 558.37 569.31 559.8 536.04 549.56 536.62 548.2 526.84 545.17 545.82 483.58 556.3 486.91 558.8 478.4 557.44 478.1"
            onClick={() => window.open("/mieszkania/b-5-9/", "_blank")}
          />
        </g>
        <g id="B_5_10">
          <polygon
            className={clsx("cls-1", getStatusClass(apartmentList, "b-5-10"))}
            points="681.87 424.54 683.22 424.93 664.59 486.1 663.04 485.65 660.61 493.52 563.22 463.52 566.46 453.01 570.82 454.09 574.2 444.42 569.62 442.74 572.02 434.94 570.85 434.5 574.52 422.58 575.67 423.1 579.53 410.58 590.86 411.37 590.26 421.51 596.73 422.09 597.36 411.82 683.93 417.85 681.87 424.54"
            onClick={() => window.open("/mieszkania/b-5-10/", "_blank")}
          />
        </g>
        <g id="B_5_11">
          <path
            className={clsx("cls-1", getStatusClass(apartmentList, "b-5-11"))}
            d="m680.32,342.8l-89.96-6.25-1.36,19.68,6.49.37-.37,6.07-6.54-.46-.29,4.16-1.24-.17-.87,12.53h1.26l-.69,10.14-1.77,4.77,2.99,1.3-4.26,13.81s13.97.79,14.24.69.71-10.22.71-10.22l6.46.41-.81,10.24,91.47,6.34,2.43-38.99-18.85-1.29,2.27-30.66-1.47-.04.17-2.43Z"
            onClick={() => window.open("/mieszkania/b-5-11/", "_blank")}
          />
        </g>
        <g id="B_5_12">
          <polygon
            className={clsx("cls-1", getStatusClass(apartmentList, "b-5-12"))}
            points="595.48 262.08 685.45 268.33 685.28 270.76 686.78 270.89 684.6 301.82 703.28 303.12 700.64 341.73 609.42 335.41 609.89 324.94 603.77 324.74 603.07 334.96 590.49 334.09 592.64 304.06 591.31 304.19 592.18 291.66 593.42 291.91 593.47 287.48 600.24 288.2 600.72 282.47 594.12 281.76 595.48 262.08"
            onClick={() => window.open("/mieszkania/b-5-12/", "_blank")}
          />
        </g>
        <g id="B_5_13">
          <polygon
            className={clsx("cls-1", getStatusClass(apartmentList, "b-5-13"))}
            points="597.69 214.75 596.84 227.15 598.1 227.14 596.83 245.09 600.2 245.32 599.17 260.02 608.4 260.67 609.11 250.45 615.52 250.94 614.75 261.11 705.96 267.44 708.53 230.02 679.55 227.81 680 222.89 681.47 223.05 683.38 195.93 681.89 195.86 682.06 193.43 604.18 188.01 602.82 207.69 605.86 207.96 605.34 213.63 598.8 213.18 598.91 214.79 597.69 214.75"
            onClick={() => window.open("/mieszkania/b-5-13/", "_blank")}
          />
        </g>
        <g id="B_5_14">
          <polygon
            className={clsx("cls-1", getStatusClass(apartmentList, "b-5-14"))}
            points="729.78 94.41 717.05 93.57 717.06 94.88 715.58 94.66 714.93 99.22 692.67 97.17 685.6 178.05 700.03 179.31 699.85 180.78 718.16 182.18 718.7 180.94 723.87 181.4 722.56 196.32 724.05 196.45 723.95 197.95 753.19 200.35 753.79 199.06 756.21 199.27 763.48 116.15 736.21 113.71 737.67 100.17 733.37 99.79 733.69 96.24 729.25 95.87 729.78 94.41"
            onClick={() => window.open("/mieszkania/b-5-14/", "_blank")}
          />
        </g>
        <g id="B_5_15">
          <path
            className={clsx("cls-1", getStatusClass(apartmentList, "b-5-15"))}
            d="m837,134.71l4-45.78-2.06-.18.12-1.5-23.09-2.17-.4,1.37-4.67-.41.07-3.5-6.54-.57-.31-1.61-11.87-1.07.11,1.63-9.45-.81.15-1.47-12.2-.84-.04,1.48-13.95-.98.08-1.51-12.05-.83-.05,1.51-2.51-.18-.16,1.35-1.22.08-1.07,12.31,1.25.18-1.75,19.8,10.09.89.67-4.83,16.43,1.27-8.38,97.07,33.79,2.98.14-1.49,4.91.66-.31,6.52,32.03,2.65-.13-1.38,1.31.12,5.96-66.93-4.47-.4,1.1-13.99,4.47.56Zm-43.24-27.5l-11.7-1.1.52-6.7,11.76,1.27-.58,6.53Zm12.99,40.79l-5.75-.5.74-8.08,5.65.5-.64,8.08Z"
            onClick={() => window.open("/mieszkania/b-5-15/", "_blank")}
          />
        </g>
        <g id="C_5_1">
          <path
            className={clsx("cls-1", getStatusClass(apartmentList, "c-5-1"))}
            d="m943.09,95.59l.32-1.58-8.39-.79.02-1.21-11.59-1.11-.16,1.6-9.33-.8.1-1.51-11.61-.93-.56,1.38-9.33-.82.1-1.51-12.1-1.02-.3,1.21-6.43-.32-.56,3.46-4.66-.39.11-1.27-23.67-1.79.15,1.26-2.05-.19-11.07,126.54,1.05.35-.02,1.75,70.31,5.73,9.45-103.19,16.92,1.59-.43,5.1,10.81.96,1.63-20,1.38.02,1.07-12.37-1.16-.15Zm-66.71,53.68l-6.17-.62.88-9.26,6.15.53-.86,9.35Zm5.59-34.11l-11.91-1.13.44-6.52,12.08,1.08-.61,6.57Z"
            onClick={() => window.open("/mieszkania/c-5-1/", "_blank")}
          />
        </g>
        <g id="C_5_2">
          <polygon
            className={clsx("cls-1", getStatusClass(apartmentList, "c-5-2"))}
            points="965.7 113.34 953.07 112.62 952.85 113.8 948.04 113.79 947.79 116.73 943.72 116.62 942.17 131.44 914.69 128.82 907.38 212.56 909.81 212.77 909.68 214.38 944.55 217.32 946.02 200.89 951.17 201.34 950.99 202.8 967.34 204.22 967.42 202.75 980.59 203.9 987.81 121.29 966.68 119.26 966.79 115.06 965.16 114.93 965.7 113.34"
            onClick={() => window.open("/mieszkania/c-5-2/", "_blank")}
          />
        </g>
        <g id="C_5_3">
          <polygon
            className={clsx("cls-1", getStatusClass(apartmentList, "c-5-3"))}
            points="1068.08 250.24 1066.74 237.73 1065.42 237.88 1065.46 236.07 1058.76 237.38 1058.04 231.68 1064.6 230.69 1062.39 211.07 981.47 220.21 981.75 222.62 980.25 222.82 983.03 249.74 984.79 249.56 985.46 254.45 956.31 257.96 960.94 294.73 1051.62 284.94 1050.69 274.51 1056.8 274.04 1057.95 284.22 1070.48 282.81 1066.62 250.37 1068.08 250.24"
            onClick={() => window.open("/mieszkania/c-5-3/", "_blank")}
          />
        </g>
        <g id="C_5_4">
          <polygon
            className={clsx("cls-1", getStatusClass(apartmentList, "c-5-4"))}
            points="1076.68 327.12 1075.26 314.54 1074 314.61 1073.52 310.45 1067.05 311.44 1066.61 305.36 1073.1 304.37 1070.68 285.13 981.26 294.97 981.34 297.66 979.59 297.89 983.36 328.68 964.74 330.78 969.27 368.75 1060.11 358.59 1058.98 348.56 1065.09 348.11 1066.24 358.28 1079.14 356.68 1075.22 327.09 1076.68 327.12"
            onClick={() => window.open("/mieszkania/c-5-4/", "_blank")}
          />
        </g>
        <g id="C_5_5">
          <polygon
            className={clsx("cls-1", getStatusClass(apartmentList, "c-5-5"))}
            points="1085.85 408.12 1084.42 395.75 1083.19 395.77 1082.3 389.88 1079.42 390.24 1075.73 359.73 1066.06 360.57 1067.7 370.94 1061.63 371.74 1060.22 361.47 969.66 371.44 973.74 410.19 992.35 408.09 995.68 438.93 997.3 438.77 997.57 441.19 1058.88 434.28 1057.81 424.84 1061.93 424.63 1060.55 412.97 1084.79 409.98 1084.27 408.15 1085.85 408.12"
            onClick={() => window.open("/mieszkania/c-5-5/", "_blank")}
          />
        </g>
        <g id="C_5_6">
          <polygon
            className={clsx("cls-1", getStatusClass(apartmentList, "c-5-6"))}
            points="1085.08 412.89 1064.6 415.2 1066.98 436.05 981.31 445.99 985.38 480.51 1074.96 470.34 1073.76 460.22 1080.15 459.44 1081.3 469.62 1091.34 468.49 1090.01 457.34 1091.33 457.21 1089.78 444.65 1088.67 444.78 1085.08 412.89"
            onClick={() => window.open("/mieszkania/c-5-6/", "_blank")}
          />
        </g>
        <g id="C_5_7">
          <polygon
            className={clsx("cls-1", getStatusClass(apartmentList, "c-5-7"))}
            points="1092.8 481.7 1091.54 494.13 1090.26 494.3 1085.82 540.38 1066.68 538.43 1070.13 507.7 986.35 517.32 984.86 517.22 987.08 495.55 985.85 482.89 1071.06 472.92 1072.32 483.25 1078.45 482.28 1077.43 472.17 1091.71 470.5 1092.23 475.05 1091.59 481.61 1092.8 481.7"
            onClick={() => window.open("/mieszkania/c-5-7/", "_blank")}
          />
        </g>
        <g id="C_5_8">
          <polygon
            className={clsx("cls-1", getStatusClass(apartmentList, "c-5-8"))}
            points="1083.07 574.28 1084.56 561.7 1083.36 561.6 1085.03 542.75 1063.92 540.43 1064.95 527.91 984.18 519.7 977.36 586.63 1044.83 593.42 1046.84 580.24 1076.4 583.08 1077.05 575.14 1081.64 575.93 1081.72 573.86 1083.07 574.28"
            onClick={() => window.open("/mieszkania/c-5-8/", "_blank")}
          />
        </g>
        <g id="C_5_9">
          <polygon
            className={clsx("cls-1", getStatusClass(apartmentList, "c-5-9"))}
            points="1075.84 646.48 1077.11 634.04 1075.74 634.01 1078.05 612.68 1073.61 612.23 1075.03 596.87 1079.61 597.33 1080.28 585.9 1048.94 582.95 1047.37 595.92 986.73 590 986.39 595.37 984.95 595.71 983.56 616.55 994.4 620.53 993.89 622.06 1015.38 629.77 1016.35 628.43 1036.24 635.59 1036.17 637.07 1067.71 648.6 1068.66 647.27 1074.3 649.29 1074.68 645.98 1075.84 646.48"
            onClick={() => window.open("/mieszkania/c-5-9/", "_blank")}
          />
        </g>
        <g id="C_5_10">
          <polygon
            className={clsx("cls-1", getStatusClass(apartmentList, "c-5-10"))}
            points="1098.76 636.46 1097.46 648.86 1098.72 648.9 1098.05 652.86 1087.59 652.04 1082.67 700.26 1091.62 701.17 1091.48 702.77 1125.87 706.17 1126.04 704.67 1147.9 706.89 1147.57 708.4 1181.74 711.77 1182.33 710.41 1186.6 710.36 1187.47 699.44 1189.08 699.16 1192.45 664.98 1190.97 665.03 1191.49 659.96 1211.58 661.47 1216.05 618.25 1138.9 610.44 1137.94 619.93 1127.92 619.11 1129.11 609.12 1102.78 606.51 1100.03 636.73 1098.76 636.46"
            onClick={() => window.open("/mieszkania/c-5-10/", "_blank")}
          />
        </g>
        <g id="C_5_11">
          <polygon
            className={clsx("cls-1", getStatusClass(apartmentList, "c-5-11"))}
            points="1106.2 562.11 1104.94 574.47 1102.9 604.42 1194.49 613.57 1194.71 611.53 1196.4 611.44 1199.09 580.54 1219.6 582.62 1223.54 543.85 1113.6 532.67 1112.1 547.32 1108.59 547.01 1107.42 562.2 1106.2 562.11"
            onClick={() => window.open("/mieszkania/c-5-11/", "_blank")}
          />
        </g>
        <g id="C_5_12">
          <polygon
            className={clsx("cls-1", getStatusClass(apartmentList, "c-5-12"))}
            points="1113.51 460.79 1114.95 473.16 1116.23 472.88 1116.45 475.69 1122.97 474.94 1123.84 482.02 1118.33 482.89 1118.69 486.05 1116.97 502.9 1210.46 492.35 1210.19 489.93 1211.59 489.33 1208.18 458.92 1228.65 456.61 1224.29 417.95 1129.92 428.54 1131.13 438.63 1125.32 439.61 1124.17 429.43 1111.39 430.87 1115.12 460.8 1113.51 460.79"
            onClick={() => window.open("/mieszkania/c-5-12/", "_blank")}
          />
        </g>
        <g id="C_5_13">
          <polygon
            className={clsx("cls-1", getStatusClass(apartmentList, "c-5-13"))}
            points="1102.89 356.58 1106.37 386.24 1105.01 386.49 1106.39 398.82 1107.65 398.84 1107.9 401 1114.42 400.28 1115.33 407.71 1108.77 408.7 1110.98 428.31 1201.95 418.04 1201.69 415.62 1203.17 415.61 1199.66 384.63 1220.14 382.31 1215.78 343.64 1121.75 354.45 1122.61 364.32 1116.57 365.34 1115.42 355.16 1102.89 356.58"
            onClick={() => window.open("/mieszkania/c-5-13/", "_blank")}
          />
        </g>
        <g id="C_5_14">
          <polygon
            className={clsx("cls-1", getStatusClass(apartmentList, "c-5-14"))}
            points="1096.67 312.14 1098.06 324.49 1099.23 324.4 1099.56 326.68 1106.07 325.94 1106.98 333.39 1100.43 334.38 1102.38 353.88 1193.62 343.72 1193.34 341.3 1194.9 340.63 1191.33 310.29 1211.81 307.98 1207.07 269.3 1113.41 280.12 1114.59 290.31 1108.23 291.01 1107.08 280.83 1094.55 282.24 1097.93 312.09 1096.67 312.14"
            onClick={() => window.open("/mieszkania/c-5-14/", "_blank")}
          />
        </g>
        <g id="C_5_15">
          <path
            className={clsx("cls-1", getStatusClass(apartmentList, "c-5-15"))}
            d="m1088.33,237.89l1.37,12.39,1.32-.11.19,2.42,6.52-.73.91,7.43-6.83.78,2.49,19.83,90.97-10.27-.28-2.42,1.93-.24-3.94-30.76,20.48-2.31-4.35-38.53s-93.82,10.26-94.04,10.67,1.02,10.22,1.02,10.22l-5.96.64-1.14-10.18-9.44,1.06,1.65,14.65-3.34.38,1.94,15.25-1.47-.17Z"
            onClick={() => window.open("/mieszkania/c-5-15/", "_blank")}
          />
        </g>
        <g id="C_5_16">
          <path
            className={clsx("cls-1", getStatusClass(apartmentList, "c-5-16"))}
            d="m1060.53,117l4.67,41.41,13.85-1.32,1.97,16.15,1.24-.05.12,1.04,4.36-.24,2.26,17.89-4.57.53,1.49,13.22,90.59-10.21v-2.2s1.37-.41,1.37-.41l-3.76-31.8,20.39-2.29-4.48-37.86-1.3-.29-.34-2.97-45.78,5.17s-.51-8.91-.99-8.92-11.99,1.36-11.99,1.36l.14,1.5-11.78,1.46-1.11-7.52-7.71.87-.22-1.74-11.93,1.61.16,1.49-16.94,1.91-.18-1.49-12.02,1.4.22,1.45-7.73.87Z"
            onClick={() => window.open("/mieszkania/c-5-16/", "_blank")}
          />
        </g>
      </svg>
    </>
  )
}

export default BuildingFloor3
