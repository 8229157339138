import React, { useState } from "react"
import { graphql } from "gatsby"

import Layout from "components/Layout"
import Breadcrumbs from "components/Breadcrumbs"
import PageHeader from "components/PageHeader"

import { Listing, Building } from "page_components/apartments"

const Apartments = ({ location, data }) => {
  const [view, setView] = useState("listing")

  const title = "Mieszkania"
  const allApartments = data.allWpApartment.nodes
  const apartmentData = {
    view,
    setView,
  }

  return (
    <Layout seo={{ title: title }} isApartment>
      <Breadcrumbs title={title} />
      <PageHeader title={title} />
      {view === "listing" ? (
        <Listing
          apartmentList={allApartments}
          apartmentData={apartmentData}
          state={location.state}
        />
      ) : (
        <Building apartmentList={allApartments} apartmentData={apartmentData} />
      )}
    </Layout>
  )
}

export const query = graphql`
  query {
    allWpApartment(sort: { fields: title, order: ASC }) {
      nodes {
        id
        title
        uri
        slug
        acfApartment {
          apartmentNumber
          buildingNumber
          area
          floor
          location
          price
          regularPrice
          discountText
          priceForSquareMeter
          roomsnumber
          status
          dollhouseView
        }
      }
    }
  }
`

export default Apartments
