import React from "react"
import clsx from "clsx"

import { getStatusClass } from "../../utils"

const BuildingFloor4 = ({ apartmentList }) => {
  return (
    <>
      <img
        src={require("assets/images/building/lobzow-floor-4.png").default}
        alt=""
      />
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="1318"
        height="740"
        viewBox="0 0 1318 740"
      >
        <g id="A_6_1">
          <polygon
            className={clsx("cls-1", getStatusClass(apartmentList, "a-6-1"))}
            points="191.95 64.45 194.06 52.03 192.9 51.73 196.99 27.24 192.31 26.45 192.61 24.52 169.79 21.05 169.08 22.5 167.27 22.19 166.39 27.31 154.41 25.26 155.53 18.53 143.44 16.58 143.16 18.08 122.8 14.48 111.39 52.88 112.66 52.98 104.59 81.28 103.12 80.86 96 105.23 97.25 106.06 93.15 119.85 108.56 124.5 108.36 125.69 119.66 129.26 120.37 128.13 144.73 135.36 144.25 136.58 169.05 143.97 169.57 142.51 176.92 144.69 190.66 64.46 191.95 64.45"
            onClick={() => window.open("/mieszkania/a-6-1/", "_blank")}
          />
        </g>
        <g id="A_6_2">
          <polygon
            className={clsx("cls-1", getStatusClass(apartmentList, "a-6-2"))}
            points="242.95 76.62 230.42 74.44 229.99 75.66 197.43 70.12 195.44 81.86 190.74 80.8 179.94 145.55 183.4 146.58 182.82 147.96 206.14 154.79 206.98 153.55 220.78 157.42 220.33 159.01 245.11 166.24 245.56 164.78 250.76 166.32 262.69 96.14 257.66 95.02 260.17 80.79 242.63 77.81 242.95 76.62"
            onClick={() => window.open("/mieszkania/a-6-2/", "_blank")}
          />
        </g>
        <g id="A_6_3">
          <polygon
            className={clsx("cls-1", getStatusClass(apartmentList, "a-6-3"))}
            points="318.52 85.81 305.76 83.47 305.52 84.85 303.41 84.42 302.83 87.8 298.61 86.9 298.9 83.73 268.84 78.62 266.61 91.76 278.32 93.75 277.24 100.01 265.5 98.27 254.09 165.37 256.52 165.79 256.29 167.31 291.37 173.26 291.64 171.76 299.38 173.08 299.14 174.59 325.02 178.99 325.29 177.48 331.53 178.55 337.67 90.32 318.02 87.13 318.52 85.81"
            onClick={() => window.open("/mieszkania/a-6-3/", "_blank")}
          />
        </g>
        <g id="A_6_4">
          <polygon
            className={clsx("cls-1", getStatusClass(apartmentList, "a-6-4"))}
            points="323.92 71.09 321.83 83.39 323.08 83.66 322.82 85.16 340.29 88.39 340.44 89.94 349.54 90.82 349.25 99.93 339.8 99.07 335.37 162.81 337.84 162.98 337.52 163.99 358.09 165.69 358.61 164.6 371.65 150.1 373.5 127.33 384.65 128.04 384.55 129.52 412.61 131.23 415.12 101.89 406.62 101.3 406.97 94.23 420.85 95.53 421.2 96.85 440.91 98.26 443.38 58.13 429.9 57.45 429.99 55.8 406.19 54.3 406.19 55.8 378.21 53.85 378.32 52.06 366.06 51.47 365.93 53 355.41 52.27 355.51 50.62 343.2 49.88 342.88 51.15 333.7 50.75 330.65 70.51 325.51 69.64 325.18 71.4 323.92 71.09"
            onClick={() => window.open("/mieszkania/a-6-4/", "_blank")}
          />
        </g>
        <g id="B_6_1">
          <path
            className={clsx("cls-1", getStatusClass(apartmentList, "b-6-1"))}
            d="m642.4,71.86l.09-1.53-23.44-1.59-.56,1.22-27.94-1.81.05-1.34-12.31-.96-.12,1.53-9.24-.9.08-1.54-23.99-1.64-.04,1.52-9.11-.64.09-1.53-24-1.66-.04,1.54-4.48-.31.09-1.67-24.02-1.53-.28,1.25-9.12-.37.09-1.65-23.98-2.08-.29,1.8-3.82-.02-2.79,40.51,31.41,2.62.31-1.98,111.32,7.71-.96,13.58,1.66.12-.03,1.29,12.7.95-.19-1.3,15.34.92,1.12-14.21,30.37,1.8,2.36-37.54-6.36-.55Zm-107.17,19.91l-8.94-.8.88-11.9,8.91.65-.85,12.04Zm66.36.13l-6.35-.29.92-9.27,6.22.4-.78,9.16Z"
            onClick={() => window.open("/mieszkania/b-6-1/", "_blank")}
          />
        </g>
        <g id="B_6_2">
          <path
            className={clsx("cls-1", getStatusClass(apartmentList, "b-6-2"))}
            d="m583.23,109.69l-17.93-1.25-2.88-.2-82.08-5.7-1.29,18.57-1.53-.08-.92,14.59,1.41.54-.11,1.51-1.53-.08-2.63,37.87,83.94,5.86,2.46-36.24,20.54,1.52.12-1.91h1.27s.9-12.84.9-12.84l-1.27-.18,1.53-21.98Zm-15.14,20.54l-6.67-.73.84-11.31,2.07.1-.02.24,4.57.32-.79,11.37Z"
            onClick={() => window.open("/mieszkania/b-6-2/", "_blank")}
          />
        </g>
        <g id="B_6_3">
          <polygon
            className={clsx("cls-1", getStatusClass(apartmentList, "b-6-3"))}
            points="561.84 158.24 560.02 184.44 474.87 178.51 473.81 189.31 472.23 189.65 470.57 214.05 558.8 220.14 559.52 209.86 565.75 210.4 565.29 220.58 575.59 221.3 576.25 209.88 577.65 209.91 578.56 197.17 577.18 197.24 580.58 149.57 569.84 148.83 568.9 158.74 561.84 158.24"
            onClick={() => window.open("/mieszkania/b-6-3/", "_blank")}
          />
        </g>
        <g id="B_6_4">
          <polygon
            className={clsx("cls-1", getStatusClass(apartmentList, "b-6-4"))}
            points="575.91 235.81 575.02 248.48 573.76 248.48 570.48 295.5 559.78 294.76 560.48 284.61 553.2 283.91 554.94 257.82 468.31 251.95 470.02 227.27 471.55 227.36 472.28 216.84 558.7 222.1 557.89 233.02 565.28 233.25 566.05 222.68 575.58 223.63 574.65 235.67 575.91 235.81"
            onClick={() => window.open("/mieszkania/b-6-4/", "_blank")}
          />
        </g>
        <g id="B_6_5">
          <path
            className={clsx("cls-1", getStatusClass(apartmentList, "b-6-5"))}
            d="m570.05,299.67l.1-1.41-20.79-1.33,2.76-36.38-84.01-5.68-1.94,24.61,1.51.06-.93,13.32,10.85.62-.44,5.52-1.54-.08-1.17,17.42,1.51.04-.88,12.5,89.07,6.19s.79-15.23,1.04-14.96c.24.27,3.43.23,3.43.23l.58-7.84,1.24.04.88-12.77-1.27-.1Zm-15.19,21.24l-6.58-.34.6-8.45,6.35.72-.37,8.07Z"
            onClick={() => window.open("/mieszkania/b-6-5/", "_blank")}
          />
        </g>
        <g id="B_6_6">
          <polygon
            className={clsx("cls-1", getStatusClass(apartmentList, "b-6-6"))}
            points="567.49 338.15 471.95 331.72 464.64 355.59 466.11 356.06 463.83 363.46 543.97 388.16 547.09 377.84 553.47 379.93 550.33 390.12 562.75 393.95 563.43 391 564.69 374.75 565.96 374.91 566.84 362.1 565.57 362.1 565.68 360.6 560.14 358.63 563.46 348.41 566.45 349.34 567.49 338.15"
            onClick={() => window.open("/mieszkania/b-6-6/", "_blank")}
          />
        </g>
        <g id="B_6_7">
          <polygon
            className={clsx("cls-1", getStatusClass(apartmentList, "b-6-7"))}
            points="562.12 396.77 462.93 366.21 461.49 370.9 459.91 370.42 453.07 392.71 454.47 393.62 452.51 400 450.82 399.96 443.74 423.24 445.21 423.71 441.28 436.48 521.99 461.36 525.11 451.38 532.48 453.67 529.41 463.63 540.48 467.05 549.43 438.08 550.59 438.43 554.4 426.18 553.27 425.79 554.44 421.57 548.03 419.86 549.85 413.96 556.21 415.98 562.12 396.77"
            onClick={() => window.open("/mieszkania/b-6-7/", "_blank")}
          />
        </g>
        <g id="B_6_8">
          <polygon
            className={clsx("cls-1", getStatusClass(apartmentList, "b-6-8"))}
            points="543.71 493.83 524.3 556.82 514.33 553.75 513.53 554.85 480.13 544.84 480.65 543.37 465.09 538.54 463.64 543.67 464.91 543.79 462.2 553.2 410.11 537.61 416.54 516.69 415.15 515.78 421.94 493.47 423.55 493.95 429.76 472.96 428.5 472.29 435.63 449.62 437.08 450.04 440.46 439.1 504.6 458.85 502.49 465.79 508.81 467.69 511.03 460.91 539.7 469.66 539.37 470.32 540.73 470.69 537 482.78 535.73 482.55 532.99 490.72 543.71 493.83"
            onClick={() => window.open("/mieszkania/b-6-8/", "_blank")}
          />
        </g>
        <g id="B_6_9">
          <polygon
            className={clsx("cls-1", getStatusClass(apartmentList, "b-6-9"))}
            points="558.13 489.13 561.83 477.09 563.04 477.41 563.35 476.85 591.94 485.68 589.87 492.33 596.17 494.38 598.22 487.61 662.01 507.27 660.34 512.72 663.16 520.07 653.82 550.4 649.09 549.02 645.63 560.41 647.09 560.88 636.76 594.36 635.3 593.93 632.72 602.28 582.1 586.74 586.28 575.03 570.56 570.53 570.17 571.97 536.43 561.59 537.02 560.21 527.1 557.14 546.35 494.69 556.98 498.07 559.51 489.43 558.13 489.13"
            onClick={() => window.open("/mieszkania/b-6-9/", "_blank")}
          />
        </g>
        <g id="B_6_10">
          <polygon
            className={clsx("cls-1", getStatusClass(apartmentList, "b-6-10"))}
            points="685.02 434.24 686.4 434.64 667.42 496.95 665.84 496.49 663.37 504.51 564.17 473.95 567.47 463.24 571.91 464.34 575.35 454.49 570.69 452.78 573.13 444.84 571.94 444.39 575.68 432.25 576.85 432.78 580.78 420.02 592.32 420.83 591.71 431.16 598.3 431.75 598.94 421.29 687.12 427.43 685.02 434.24"
            onClick={() => window.open("/mieszkania/b-6-10/", "_blank")}
          />
        </g>
        <g id="B_6_11">
          <path
            className={clsx("cls-1", getStatusClass(apartmentList, "b-6-11"))}
            d="m683.27,350.99l-91.38-6.35-1.38,19.99,6.59.38-.38,6.16-6.65-.47-.3,4.23-1.26-.17-.89,12.73h1.28l-.7,10.3-1.8,4.85,3.03,1.32-4.32,14.03s14.19.81,14.46.7.73-10.38.73-10.38l6.56.42-.83,10.41,92.92,6.45,2.47-39.61-19.15-1.31,2.31-31.14-1.49-.04.17-2.47Z"
            onClick={() => window.open("/mieszkania/b-6-11/", "_blank")}
          />
        </g>
        <g id="B_6_12">
          <polygon
            className={clsx("cls-1", getStatusClass(apartmentList, "b-6-12"))}
            points="597.17 268.46 688.6 274.81 688.42 277.28 689.96 277.42 687.74 308.85 706.72 310.17 704.03 349.41 611.33 342.99 611.81 332.35 605.59 332.15 604.88 342.53 592.09 341.64 594.28 311.12 592.92 311.26 593.81 298.52 595.07 298.78 595.13 294.28 602 295 602.49 289.18 595.78 288.46 597.17 268.46"
            onClick={() => window.open("/mieszkania/b-6-12/", "_blank")}
          />
        </g>
        <g id="B_6_13">
          <polygon
            className={clsx("cls-1", getStatusClass(apartmentList, "b-6-13"))}
            points="599.18 219.49 598.32 232.11 599.59 232.1 598.3 250.37 601.73 250.61 600.68 265.58 610.08 266.24 610.8 255.83 617.33 256.33 616.55 266.68 709.4 273.13 712.01 235.03 682.52 232.78 682.97 227.78 684.47 227.94 686.42 200.32 684.9 200.26 685.07 197.78 605.78 192.26 604.4 212.3 607.5 212.58 606.97 218.34 600.31 217.88 600.42 219.53 599.18 219.49"
            onClick={() => window.open("/mieszkania/b-6-13/", "_blank")}
          />
        </g>
        <g id="B_6_14">
          <polygon
            className={clsx("cls-1", getStatusClass(apartmentList, "b-6-14"))}
            points="734.72 96.55 721.79 95.71 721.8 97.04 720.3 96.81 719.64 101.44 697.04 99.36 689.86 181.49 704.52 182.77 704.33 184.26 722.92 185.68 723.47 184.42 728.72 184.89 727.39 200.04 728.9 200.18 728.8 201.69 758.49 204.13 759.1 202.82 761.56 203.03 768.94 118.63 741.25 116.15 742.73 102.41 738.37 102.02 738.69 98.42 734.18 98.04 734.72 96.55"
            onClick={() => window.open("/mieszkania/b-6-14/", "_blank")}
          />
        </g>
        <g id="B_6_15">
          <path
            className={clsx("cls-1", getStatusClass(apartmentList, "b-6-15"))}
            d="m843.42,137.86l4.06-46.49-2.09-.18.12-1.52-23.45-2.2-.41,1.39-4.74-.42.07-3.55-6.64-.58-.31-1.63-12.05-1.09.11,1.66-9.6-.82.15-1.49-12.39-.85-.04,1.5-14.17-1,.08-1.53-12.24-.84-.05,1.53-2.55-.18-.16,1.37-1.24.08-1.09,12.5,1.27.18-1.78,20.11,10.25.9.68-4.9,16.68,1.29-8.51,98.58,34.31,3.03.14-1.51,4.99.67-.31,6.62,32.53,2.69-.13-1.4,1.33.12,6.05-67.97-4.54-.41,1.12-14.21,4.54.57Zm-43.91-27.93l-11.88-1.12.53-6.8,11.94,1.29-.59,6.63Zm13.19,41.42l-5.84-.51.75-8.21,5.74.51-.65,8.21Z"
            onClick={() => window.open("/mieszkania/b-6-15/", "_blank")}
          />
        </g>
        <g id="C_6_1">
          <path
            className={clsx("cls-1", getStatusClass(apartmentList, "c-6-1"))}
            d="m952.4,98.01l.33-1.61-8.55-.81.02-1.23-11.81-1.13-.16,1.63-9.51-.82.1-1.54-11.83-.95-.57,1.41-9.51-.84.1-1.54-12.33-1.04-.31,1.23-6.55-.33-.57,3.53-4.75-.4.11-1.29-24.12-1.82.15,1.28-2.09-.19-11.28,128.97,1.07.36-.02,1.78,71.66,5.84,9.63-105.17,17.24,1.62-.44,5.2,11.02.98,1.66-20.38,1.41.02,1.09-12.61-1.18-.15Zm-67.99,54.71l-6.29-.63.9-9.44,6.27.54-.88,9.53Zm5.7-34.77l-12.14-1.15.45-6.65,12.31,1.1-.62,6.7Z"
            onClick={() => window.open("/mieszkania/c-6-1/", "_blank")}
          />
        </g>
        <g id="C_6_2">
          <polygon
            className={clsx("cls-1", getStatusClass(apartmentList, "c-6-2"))}
            points="975.74 115.91 962.91 115.17 962.68 116.38 957.78 116.37 957.53 119.36 953.39 119.25 951.81 134.32 923.86 131.66 916.43 216.83 918.9 217.04 918.77 218.68 954.23 221.67 955.73 204.96 960.97 205.41 960.78 206.9 977.41 208.34 977.49 206.85 990.9 208.02 998.24 123.99 976.74 121.94 976.86 117.66 975.19 117.53 975.74 115.91"
            onClick={() => window.open("/mieszkania/c-6-2/", "_blank")}
          />
        </g>
        <g id="C_6_3">
          <polygon
            className={clsx("cls-1", getStatusClass(apartmentList, "c-6-3"))}
            points="1080.39 256.17 1079.03 243.45 1077.69 243.59 1077.73 241.75 1070.91 243.08 1070.18 237.29 1076.85 236.28 1074.61 216.32 992.28 225.61 992.56 228.08 991.04 228.28 993.87 255.66 995.65 255.48 996.34 260.46 966.69 264.02 971.39 301.43 1063.65 291.47 1062.71 280.86 1068.92 280.39 1070.09 290.74 1082.84 289.3 1078.91 256.3 1080.39 256.17"
            onClick={() => window.open("/mieszkania/c-6-3/", "_blank")}
          />
        </g>
        <g id="C_6_4">
          <polygon
            className={clsx("cls-1", getStatusClass(apartmentList, "c-6-4"))}
            points="1089.37 334.57 1087.91 321.74 1086.63 321.81 1086.15 317.56 1079.54 318.57 1079.09 312.37 1085.72 311.36 1083.25 291.73 992 301.77 992.08 304.52 990.3 304.75 994.14 336.16 975.14 338.31 979.76 377.06 1072.46 366.69 1071.31 356.45 1077.54 355.99 1078.71 366.38 1091.88 364.75 1087.87 334.54 1089.37 334.57"
            onClick={() => window.open("/mieszkania/c-6-4/", "_blank")}
          />
        </g>
        <g id="C_6_5">
          <path
            className={clsx("cls-1", getStatusClass(apartmentList, "c-6-5"))}
            d="m1097.47,419.89l1.39-.18-1.43-12.64-1.33.24-.66-8.57-3.61.67-3.53-31.37-9.61,1.24,1.42,10.18-6.17,1.01-1.46-10.65-92.58,10.48,4.46,39.17,18.96-2.14,2.32,19.6,16.99,11.52,3.23.67,14.36-1.99.93,5.97-1.39.6,3.27,29.48,1.49-.23.6,2.68,38.43-4.6-.98-10.99,10.25-.55,1.17,10.37,10.24-1.16-6.76-58.81Zm-26.4,24.21l-1.07-9.63,6.02-.74,1.32,9.71-6.27.66Z"
            onClick={() => window.open("/mieszkania/c-6-5/", "_blank")}
          />
        </g>
        <g id="C_6_6">
          <path
            className={clsx("cls-1", getStatusClass(apartmentList, "c-6-6"))}
            d="m1105.08,486.13l-.71-4.88-14.5,1.9,1.55,13.66-5.94.75-1.8-13.71-87.31,9.86,4.18,35.25,1.21,1.01-.51,15.79,53.79,37.67,1.32-1.1,4.04,2.85-4.89,15.17,27.92,9.52,1.14-1.82,7.82,2.53,1.36-13.39-7.6-.78.84-8.16,7.6.77,5.45-54.86,1.42.06,1.28-12.6-1.27-.12,3.61-35.37Zm-27.13,53.97l-36.05-3.82-1.6-11.94,39.91-4.51-2.26,20.27Z"
            onClick={() => window.open("/mieszkania/c-6-6/", "_blank")}
          />
        </g>
        <g id="C_6_7">
          <polygon
            className={clsx("cls-1", getStatusClass(apartmentList, "c-6-7"))}
            points="1105.55 541.65 1117.99 542.92 1117.92 544.2 1121.13 544.52 1113.39 620.5 1142.57 623.47 1141.57 633.35 1152.01 634.41 1152.82 624.26 1231.52 632.55 1226.88 676.94 1207.76 675 1203.78 715.35 1202.26 715.21 1201.07 726.8 1197.02 726.38 1196.67 727.85 1170.47 725.19 1170.68 723.7 1162.04 722.82 1148.46 700.45 1147.07 701.39 1101.2 626.78 1102.49 625.97 1094.9 613.61 1102.13 542.58 1105.74 542.84 1105.55 541.65"
            onClick={() => window.open("/mieszkania/c-6-7/", "_blank")}
          />
        </g>
        <g id="C_6_8">
          <polygon
            className={clsx("cls-1", getStatusClass(apartmentList, "c-6-8"))}
            points="1144.86 545.67 1157.31 546.92 1157.23 548.29 1239.16 556.56 1235.13 595.79 1214.24 593.66 1211.02 625.19 1209.53 624.98 1208.85 627.66 1152.8 621.7 1116.46 617.99 1118.5 597.99 1126.08 598.9 1126.89 590.92 1119.05 590.01 1123.91 548.01 1136.62 549.56 1135.9 556.52 1142.11 557.16 1143.16 546.77 1144.67 546.92 1144.86 545.67"
            onClick={() => window.open("/mieszkania/c-6-8/", "_blank")}
          />
        </g>
        <g id="C_6_9">
          <polygon
            className={clsx("cls-1", getStatusClass(apartmentList, "c-6-9"))}
            points="1127.05 471.43 1128.51 483.99 1129.82 483.71 1130.04 486.55 1136.66 485.8 1137.55 492.98 1131.95 493.86 1132.31 497.07 1130.57 514.18 1225.48 503.47 1225.2 501.02 1226.62 500.4 1223.16 469.54 1243.94 467.19 1239.52 427.94 1143.72 438.69 1144.94 448.94 1139.05 449.93 1137.88 439.6 1124.9 441.06 1128.69 471.44 1127.05 471.43"
            onClick={() => window.open("/mieszkania/c-6-9/", "_blank")}
          />
        </g>
        <g id="C_6_10">
          <polygon
            className={clsx("cls-1", getStatusClass(apartmentList, "c-6-10"))}
            points="1115.96 365.15 1119.5 395.39 1118.12 395.64 1119.53 408.21 1120.81 408.23 1121.07 410.44 1127.71 409.7 1128.64 417.27 1121.96 418.28 1124.21 438.28 1216.94 427.81 1216.67 425.34 1218.18 425.33 1214.61 393.74 1235.48 391.38 1231.04 351.96 1135.19 362.98 1136.07 373.04 1129.91 374.08 1128.74 363.71 1115.96 365.15"
            onClick={() => window.open("/mieszkania/c-6-10/", "_blank")}
          />
        </g>
        <g id="C_6_11">
          <polygon
            className={clsx("cls-1", getStatusClass(apartmentList, "c-6-11"))}
            points="1110.03 319.7 1111.45 332.28 1112.63 332.19 1112.97 334.52 1119.61 333.76 1120.54 341.35 1113.86 342.36 1115.85 362.23 1208.83 351.88 1208.55 349.42 1210.14 348.73 1206.5 317.81 1227.37 315.46 1222.54 276.04 1127.09 287.06 1128.29 297.45 1121.81 298.16 1120.64 287.79 1107.86 289.23 1111.31 319.65 1110.03 319.7"
            onClick={() => window.open("/mieszkania/c-6-11/", "_blank")}
          />
        </g>
        <g id="C_6_12">
          <path
            className={clsx("cls-1", getStatusClass(apartmentList, "c-6-12"))}
            d="m1101.31,243.86l1.4,12.6,1.35-.11.19,2.46,6.63-.74.93,7.56-6.95.79,2.53,20.18,92.57-10.45-.28-2.46,1.96-.24-4.01-31.3,20.84-2.35-4.43-39.21s-95.48,10.44-95.69,10.85,1.04,10.4,1.04,10.4l-6.07.65-1.16-10.36-9.6,1.08,1.68,14.9-3.4.39,1.98,15.52-1.49-.17Z"
            onClick={() => window.open("/mieszkania/c-6-12/", "_blank")}
          />
        </g>
        <g id="C_6_13">
          <path
            className={clsx("cls-1", getStatusClass(apartmentList, "c-6-13"))}
            d="m1073.04,119.85l4.77,42.24,14.13-1.34,2.01,16.48,1.26-.05.12,1.06,4.44-.24,2.31,18.25-4.66.54,1.52,13.48,92.41-10.42v-2.24s1.4-.42,1.4-.42l-3.84-32.44,20.8-2.34-4.57-38.62-1.33-.3-.35-3.03-46.7,5.28s-.52-9.09-1.01-9.1-12.23,1.38-12.23,1.38l.15,1.53-12.02,1.49-1.13-7.67-7.87.89-.23-1.77-12.16,1.64.16,1.52-17.28,1.95-.19-1.52-12.26,1.42.23,1.48-7.88.89Z"
            onClick={() => window.open("/mieszkania/c-6-13/", "_blank")}
          />
        </g>
      </svg>
    </>
  )
}

export default BuildingFloor4
