import "./styles.scss"

import React from "react"

const PageHeader = ({ title }) => {
  return (
    <div className="page-header">
      <div className="container-fluid">
        <h1>{title}</h1>
      </div>
    </div>
  )
}

export default PageHeader
